import React, { useState, useEffect } from "react";
import { Table, Modal, Button, Spin } from "antd";
import { BarChartOutlined } from "@ant-design/icons";
import BackRouteButton from "src/common/components/BackRouteButton";

import "./index.css";

export default ({ storehouse }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const showModal = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setSelectedRecord(null);
    setIsModalVisible(false);
  };

  const prepareDataSource = () => {
    const dataSource = [];

    storehouse.productsCategories.forEach((category) => {
      category.instances.forEach((instance) => {
        const row = {
          key: instance._id,
          title:
            instance.title.replace(/пшеничная|Хлебопекарная/g, "") +
            " " +
            instance.sizeOfPack +
            "кг",
          weight: instance.weight,
        };
        dataSource.push(row);
      });
    });
    return dataSource;
  };

  const columns = [
    {
      title: <span className="column-title-font">Наименование</span>,
      dataIndex: "title",
      key: "title",
      render: (text) => <span className="column-content-font">{text}</span>,
    },
    {
      title: <span className="column-title-font">Доступно</span>,
      dataIndex: "weight",
      key: "weight",
      render: (text, record) => (
        <span className="column-content-font">
          {record.weight.totalAvailable}кг
        </span>
      ),
    },
    {
      title: <span className="column-title-font">Подробнее</span>,
      dataIndex: "moreInfo",
      key: "moreInfo",
      render: (_, record) => (
        <>
          <Button
            type="link"
            onClick={() => showModal(record)}
            className="button--balance-modal"
          >
            <BarChartOutlined />
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (!data) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [data]);
  useEffect(() => {
    if (storehouse) {
      setData(prepareDataSource());
    }
  }, [storehouse]);

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <BackRouteButton />
        <h4 className="common-title--large">Баланс продукции</h4>
        <Table pagination={false} dataSource={data} columns={columns} />
        {selectedRecord && (
          <Modal
            title={
              <h3 className="common-modal-title">{selectedRecord.title}</h3>
            }
            open={isModalVisible}
            onCancel={handleCancel}
            footer={null}
          >
            <div className="custom-modal-content">
              <span className="column-content-font">
                Всего доступно для продажи:{" "}
                {selectedRecord.weight.totalAvailable}
                кг
              </span>
              <span className="column-content-font">
                Всего на складе(дост. + зарезерв.):{" "}
                {selectedRecord.weight.totalReserved +
                  selectedRecord.weight.totalAvailable}
                кг
              </span>
              <span className="column-content-font">
                Всего зарезервировано клиентами:{" "}
                {selectedRecord.weight.totalReserved}кг
              </span>
              <span className="column-content-font">
                Всего отгружено но не оплачено:{" "}
                {selectedRecord.weight.totalShipped}кг
              </span>
              <span className="column-content-font">
                Всего оплачено но не отгружено:{" "}
                {selectedRecord.weight.totalPaid}
                кг
              </span>

              <h3 className="common-modal-title">Статистика</h3>
              <span className="column-content-font">
                Всего было произведено: {selectedRecord.weight.totalProduced}кг
              </span>
              <span className="column-content-font">
                Всего было продано: {selectedRecord.weight.totalSold}кг
              </span>
              <span className="column-content-font">
                Всего было списано: {selectedRecord.weight.totalWriteOffs}кг
              </span>
              <span className="column-content-font">
                Всего было возвратов: {selectedRecord.weight.totalReturned}кг
              </span>
            </div>
          </Modal>
        )}
      </Spin>
    </div>
  );
};
