const createSelectOptions = (array) => {
  return array.map((el) => {
    return {
      value: el.title,
      label: el.title,
    };
  });
};
const discount = (val, perc) => {
  var onePerc = val / 100;
  var result = val - onePerc * perc;
  if (result < 0) {
    return 0;
  } else {
    return result;
  }
};
const uppercaseWords = (str) =>
  str.replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase());

const onSearch = (v, counteragents) => {
  if (v !== "") {
    const keys = [
      "firstname",
      "lastname",
      "additionalName",
      "firm",
      "phone",
      "email",
      "address",
    ];
    var filtered = counteragents.find((c) => {
      var result;
      keys.forEach((key) => {
        if (c[key]) {
          if (c[key].startsWith(v) || c[key].startsWith(uppercaseWords(v))) {
            result = c;
          }
        }
      });
      return result;
    });
    return filtered;
  } else {
    return false;
  }
};

function preloader(array, index) {
  if (index <= 25) {
    return array;
  } else {
    return array.slice(0, index + 25);
  }
}

function formatDate(date) {
  const options = {
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    year: "numeric",
    timeZone: "Europe/Moscow",
  };

  const dateTimeFormat = new Intl.DateTimeFormat("ru-RU", options);
  const formattedDate = dateTimeFormat.format(new Date(date));

  return formattedDate;
}

const prepareShortTitles = (str) => {
  let wordsToRemove = ["пшеничная", "Хлебопекарная"];

  for (let word of wordsToRemove) {
    let regex = new RegExp(word, "g");
    str = str.replace(regex, "");
  }

  return str.replace(/\s+/g, " ").trim();
};

export default {
  discount: discount,
  createSelectOptions: createSelectOptions,
  onSearch: onSearch,
  preloader: preloader,
  formatDate: formatDate,
  prepareShortTitles: prepareShortTitles,
};
