import { connect } from "react-redux";
import ShowCounteragentOrdersScreen from "./ShowCounteragentOrdersScreen";
import {
  cancelOrder,
  shipOrder,
  cancelShipOrder,
} from "src/orders/redux/actions";
import { getCounteragentOrders } from "src/counteragents/redux/actions";

export default connect(
  (state) => {
    return {
      counteragentOrders: state.counteragents.counteragentOrders,
      selectedCounteragent: state.counteragents.selectedCounteragent,
      profile: state.auth.profile,
    };
  },
  { cancelOrder, shipOrder, cancelShipOrder, getCounteragentOrders }
)(ShowCounteragentOrdersScreen);
