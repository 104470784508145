import { connect } from "react-redux";
import CreateOrderScreen from "./CreateOrderScreen";
import { createOrder } from "src/orders/redux/actions";

export default connect(
  (state) => {
    return {
      profile: state.auth.profile,
      counteragents: state.counteragents.counteragents,
      storehouse: state.storehouse.storehouse,
    };
  },
  { createOrder }
)(CreateOrderScreen);
