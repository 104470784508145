import { connect } from "react-redux";
import CreateStorehouseCategoryScreen from "./CreateStorehouseCategoryScreen";
import { createCategory } from "src/storehouse/redux/actions";

export default connect(
  (state) => {
    return {
      profile: state.auth.profile,
    };
  },
  { createCategory }
)(CreateStorehouseCategoryScreen);
