import { connect } from "react-redux";
import AddOnBalanceOfCounteragentScreen from "./AddOnBalanceOfCounteragentScreen";
import { addOnBalanceOfCounteragent } from "src/counters/redux/actions";

export default connect(
  (state) => {
    return {
      profile: state.auth.profile,
      counteragents: state.counteragents.counteragents,
    };
  },
  { addOnBalanceOfCounteragent }
)(AddOnBalanceOfCounteragentScreen);
