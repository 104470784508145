import { connect } from "react-redux";
import EditShiftScreen from "./EditShiftScreen";
import { editShift } from "src/processing/redux/actions";

export default connect(
  (state) => {
    return {
      profile: state.auth.profile,
      counteragents: state.counteragents.counteragents,
      storehouse: state.storehouse.storehouse,
      shifts: state.processing.shifts,
    };
  },
  { editShift }
)(EditShiftScreen);
