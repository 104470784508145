import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Button,
  message,
  InputNumber,
  Space,
  Spin,
} from "antd";
import SelectorWithAddingOptions from "src/common/components/SelectorWithAddingOptions";

import DatePicker from "src/common/components/DatePicker";
import BackRouteButton from "src/common/components/BackRouteButton";

const { Option } = Select;

export default ({
  paymentCounteragentToCounter,
  profile,
  counters,
  counteragents,
}) => {
  const history = useHistory();
  const [selectedCounteragent, setSelectedCounteragent] = useState(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);

  const onFinish = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;
    const selectedCounteragent = counteragents.find(
      (c) => c._id === values.counteragent
    );
    values.counteragent = {
      firstname: selectedCounteragent.firstname,
      lastname: selectedCounteragent.lastname,
      additionalName: selectedCounteragent.additionalName,
      firm: selectedCounteragent.firm,
      id: selectedCounteragent._id,
    };
    const { _id, title } =
      counters.find((c) => c._id === values.counter1) || {};
    values.counter1 = { id: _id, title };
    values.to = _id;
    values.from = selectedCounteragent._id;

    const result = await paymentCounteragentToCounter(values);

    if (result.success) {
      form.resetFields();
      message.success("Платёж принят!");
      history.goBack();
    } else {
      message.error(result.message || "Не удалось принять платёж!");
      setLoading(false);
    }
  };
  const handleCounteragentFilter = (input, option) => {
    const counteragent = counteragents.find((ca) => ca._id === option.value);

    const counteragentName = `${counteragent.lastname || ""} ${
      counteragent.firstname || ""
    } ${counteragent.additionalName || ""} ${counteragent.firm || ""}`;

    return counteragentName.toLowerCase().includes(input.toLowerCase());
  };
  const handleCounteragentSelect = (selectedValue) => {
    const selected = counteragents.find((ca) => ca._id === selectedValue);
    setSelectedCounteragent(selected);
    if (selected) {
      form.setFieldsValue({
        counteragentBalance: selected.balance,
      });
    }
  };

  useEffect(() => {
    if (!counteragents) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [counteragents]);

  return (
    <div className="common-container">
      <BackRouteButton />
      <h4 className="common-title--large">Принять платеж от контрагента</h4>
      <Spin spinning={loading}>
        <Form
          requiredMark={false}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label="Дата платежа"
            name="date"
            rules={[
              {
                required: true,
                message: "Пожалуйста, выберите дату!",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Платёж от"
            name="counteragent"
            rules={[
              {
                required: true,
                message: "Пожалуйста, выберите контрагента!",
              },
            ]}
          >
            <Select
              showSearch
              size="small"
              placeholder="Выберите контрагента"
              filterOption={handleCounteragentFilter}
              onSelect={handleCounteragentSelect}
            >
              {counteragents &&
                counteragents.map((counteragent) => (
                  <Option key={counteragent._id} value={counteragent._id}>
                    <h4 className="option-title-font">
                      {`${counteragent.lastname || ""} ${
                        counteragent.firstname || ""
                      } ${counteragent.additionalName || ""} ${
                        counteragent.firm || ""
                      }`}
                    </h4>
                  </Option>
                ))}
            </Select>
          </Form.Item>
          {selectedCounteragent && (
            <Form.Item label="Баланс контрагента" name="counteragentBalance">
              <InputNumber
                size="small"
                style={{
                  width: "40%",
                  color:
                    selectedCounteragent?.balance < 0
                      ? "red"
                      : selectedCounteragent?.balance > 0
                        ? "green"
                        : "inherit",
                }}
                value={
                  selectedCounteragent
                    ? `Баланс: ${selectedCounteragent.balance} руб.`
                    : ""
                }
                formatter={(value) => {
                  if (value) {
                    return `${value} руб`;
                  }
                }}
                readOnly
              />
            </Form.Item>
          )}
          <Form.Item
            label="Назначение"
            style={{ width: "90%", margin: "0px" }}
            name="title"
            rules={[
              {
                required: true,
                message: "Пожалуйста, выберите назначение платежа!",
              },
            ]}
          >
            <SelectorWithAddingOptions
              placeholder="Назначение платежа"
              listOfOptions={[
                "погашение займа контрагентом",
                "принятие займа от контрагента",
                "внесение на счёт баланса контрагента",
              ]}
              onChange={(value) => form.setFieldsValue({ title: value })}
            />
          </Form.Item>
          <Space.Compact block size="small">
            <Form.Item
              style={{ width: "50%", margin: "0px" }}
              name="sum"
              rules={[
                {
                  required: true,
                  message: "Введите сумму платежа",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} placeholder="Сумма" />
            </Form.Item>
            <Form.Item
              style={{ width: "40%", margin: "0px" }}
              name="type"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, выберите способ оплаты!",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Способ оплаты"
                optionFilterProp="children"
              >
                <Option value="наличные">
                  <h4 className="option-title-font">наличные</h4>
                </Option>
                <Option value="банковский счёт">
                  <h4 className="option-title-font">банковский счёт</h4>
                </Option>
                <Option value="перевод на карту">
                  <h4 className="option-title-font">перевод на карту</h4>
                </Option>
                <Option value="карта">
                  <h4 className="option-title-font">карта</h4>
                </Option>
              </Select>
            </Form.Item>
          </Space.Compact>
          <br />
          <Form.Item
            label="Касса/Счёт для зачисления"
            name="counter1"
            rules={[
              {
                required: true,
                message: "Выберите кассу/счёт для зачисления!",
              },
            ]}
          >
            <Select
              size="small"
              placeholder="Касса/счёт"
              optionFilterProp="children"
            >
              {counters &&
                counters.map((counter) => (
                  <Option key={counter._id} value={counter._id}>
                    <h4 className="option-title-font">{counter.title}</h4>
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item label="Комментарий" name="comment">
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Button type="primary" block size="medium" htmlType="submit">
              Принять платёж
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
