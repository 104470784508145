import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Select, Button, message, Spin } from "antd";
import BackRouteButton from "src/common/components/BackRouteButton";

const { Option } = Select;

export default ({ createCounteragent, profile }) => {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const history = useHistory();

  const onFinish = async (values) => {
    setLoading(true);

    values.creatorId = profile._id;
    const result = await createCounteragent(values);
    if (result.success) {
      form.resetFields();
      message.success("Контрагент успешно добавлен!");
      history.goBack();
    } else {
      message.error(result.message || "Не удалось создать контрагента!");
      setLoading(false);
    }
  };

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <BackRouteButton />
        <h4 className="common-title--large">Добавить контрагента</h4>

        <Form
          requiredMark={false}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label="Имя"
            name="firstname"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите имя!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Фамилия"
            name="lastname"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите фамилию!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Отчество" name="additionalName">
            <Input />
          </Form.Item>

          <Form.Item
            label="Телефон"
            name="phone"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите телефон!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Электронная почта"
            name="email"
            rules={[
              {
                type: "email",
                message: "Неверный формат электронной почты!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Компания" name="firm">
            <Input />
          </Form.Item>

          <Form.Item label="Адрес" name="address">
            <Input />
          </Form.Item>

          <Form.Item label="Комментарий" name="comment">
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            label="Тип"
            name="type"
            rules={[
              {
                required: true,
                message: "Пожалуйста, выберите тип контрагента!",
              },
            ]}
          >
            <Select placeholder="Выберите тип контрагента">
              <Option value="покупатель">Покупатель</Option>
              <Option value="поставщик">Поставщик</Option>
              <Option value="сотрудник">Сотрудник</Option>
              <Option value="работник цеха">Работник цеха</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Профессия" name="profession">
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Добавить
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
