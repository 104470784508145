import React, { useState } from "react";
import {
  Form,
  Button,
  message,
  Spin,
  Modal,
  Table,
  Radio,
  Tabs,
  Alert,
} from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import BackRouteButton from "src/common/components/BackRouteButton";
import DatePicker from "src/common/components/DatePicker";

dayjs.extend(utc);

export default ({ createReport, report }) => {
  const [loading, setLoading] = useState(false);
  const [reportType, setReportType] = useState("single");
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("product");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const onFinish = async (values) => {
    setLoading(true);

    let startDateValue, endDateValue;

    if (reportType === "single") {
      const selectedDate = values.selectedDate;
      startDateValue = dayjs(selectedDate).startOf("day").toISOString();
      endDateValue = dayjs(selectedDate).endOf("day").toISOString();
    } else {
      const startSelectedDate = values.startDate;
      const endSelectedDate = values.endDate;
      startDateValue = dayjs(startSelectedDate).startOf("day").toISOString();
      endDateValue = dayjs(endSelectedDate).endOf("day").toISOString();
    }

    setStartDate(startDateValue);
    setEndDate(endDateValue);

    const result = await createReport({
      startDate: startDateValue,
      endDate: endDateValue,
    });

    if (result.success) {
      message.success("Отчет успешно создан!");
      setIsModalVisible(true);
    } else {
      message.error(result.message || "Не удалось создать отчет!");
    }

    setLoading(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedGroup("product");
  };

  const modalTitle =
    reportType === "single"
      ? `Отчет за ${dayjs(startDate).format("DD.MM.YYYY")}`
      : `Отчет за период: ${dayjs(startDate).format("DD.MM.YYYY")} - ${dayjs(
          endDate
        ).format("DD.MM.YYYY")}`;

  const columns =
    selectedGroup === "product"
      ? [
          {
            title: <span className="column-title-font">Наименование</span>,
            dataIndex: "title",
            key: "title",
            render: (text) => (
              <span className="column-content-font">{text}</span>
            ),
          },
          {
            title: <span className="column-title-font">Произведено</span>,
            dataIndex: "totalProduced",
            key: "totalProduced",
            render: (text) => (
              <span className="column-content-font">{text} кг</span>
            ),
          },
          {
            title: <span className="column-title-font">Продано</span>,
            dataIndex: "totalSold",
            key: "totalSold",
            render: (text) => (
              <span className="column-content-font">{text} кг</span>
            ),
          },
          {
            title: <span className="column-title-font">Ср. цена продаж</span>,
            dataIndex: "averageSoldPrice",
            key: "averageSoldPrice",
            render: (text) => (
              <span className="column-content-font">{text.toFixed(2)} руб</span>
            ),
          },
        ]
      : [
          {
            title: <span className="column-title-font">Наименование</span>,
            dataIndex: "title",
            key: "title",
            render: (text) => (
              <span className="column-content-font">{text}</span>
            ),
          },
          {
            title: <span className="column-title-font">Переработано</span>,
            dataIndex: "totalProcessed",
            key: "totalProcessed",
            render: (text) => (
              <span className="column-content-font">{text} кг</span>
            ),
          },
          {
            title: <span className="column-title-font">Завезено</span>,
            dataIndex: "totalBought",
            key: "totalBought",
            render: (text) => (
              <span className="column-content-font">{text} кг</span>
            ),
          },
          {
            title: <span className="column-title-font">Ср. цена закупки</span>,
            dataIndex: "averageBoughtPrice",
            key: "averageBoughtPrice",
            render: (text) => (
              <span className="column-content-font">{text.toFixed(2)} руб</span>
            ),
          },
        ];

  const salaryColumns = [
    {
      title: <span className="column-title-font">ФИО</span>,
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <span className="column-content-font">
          {`${record.firstname} ${record.lastname} ${
            record.additionalName || ""
          }`}
        </span>
      ),
    },
    {
      title: <span className="column-title-font">Всего заработано </span>,
      dataIndex: "totalProfit",
      key: "totalProfit",
      render: (text) => <span className="column-content-font">{text} руб</span>,
    },
    {
      title: <span className="column-title-font">Текущий баланс</span>,
      dataIndex: "balance",
      key: "balance",
      render: (text) => <span className="column-content-font">{text} руб</span>,
    },
  ];

  const calculateTotal = (dataSource, field) => {
    return dataSource.reduce((acc, item) => acc + (item[field] || 0), 0);
  };

  const calculateFieldWeightedAverage = (
    dataSource,
    quantityField,
    priceField
  ) => {
    let totalQuantity = 0;
    let totalCost = 0;

    dataSource.forEach((item) => {
      const quantity = item[quantityField] || 0;
      const price = item[priceField] || 0;

      if (quantity > 0 && price > 0) {
        totalQuantity += quantity;
        totalCost += quantity * price;
      }
    });

    return totalQuantity > 0 ? totalCost / totalQuantity : 0;
  };

  const totalProduced = report
    ? calculateTotal(report["product"], "totalProduced")
    : 0;

  const totalSold = report ? calculateTotal(report["product"], "totalSold") : 0;

  const totalEarnedSalary = report
    ? calculateTotal(report["salaries"], "totalProfit")
    : 0;

  const middlePriceRawMaterial = report
    ? calculateFieldWeightedAverage(
        report["rawMaterial"],
        "totalBought",
        "averageBoughtPrice"
      )
    : 0;

  const middlePriceSoldProducts = report
    ? calculateFieldWeightedAverage(
        report["product"],
        "totalSold",
        "averageSoldPrice"
      )
    : 0;

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <BackRouteButton />
        <h4 className="common-title--large">Создать отчёт</h4>

        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item label="Тип отчета:">
            <Radio.Group
              onChange={(e) => setReportType(e.target.value)}
              value={reportType}
            >
              <Radio value="single">Один день</Radio>
              <Radio value="range">Диапазон дат</Radio>
            </Radio.Group>
          </Form.Item>

          {reportType === "single" ? (
            <Form.Item
              label="Выберите день:"
              name="selectedDate"
              rules={[{ required: true, message: "Выберите дату!" }]}
            >
              <DatePicker />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                label="Начальная дата:"
                name="startDate"
                rules={[
                  { required: true, message: "Выберите начальную дату!" },
                ]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                label="Конечная дата:"
                name="endDate"
                rules={[{ required: true, message: "Выберите конечную дату!" }]}
              >
                <DatePicker />
              </Form.Item>
            </>
          )}
          {reportType === "range" && (
            <Alert
              description="Конечная дата включается в отчёт."
              type="info"
              showIcon
              style={{ marginBottom: "16px" }}
            />
          )}
          <Form.Item>
            <Button type="primary" block size="medium" htmlType="submit">
              Создать отчет
            </Button>
          </Form.Item>
        </Form>

        {report && (
          <Modal
            title={<h3 className="common-modal-title">{modalTitle}</h3>}
            open={isModalVisible}
            onCancel={handleCancel}
            footer={null}
            width={800}
          >
            <div className="custom-modal-content">
              <Tabs
                activeKey={selectedGroup}
                onChange={(key) => setSelectedGroup(key)}
              >
                <Tabs.TabPane tab="Продукция" key="product">
                  <Table
                    pagination={false}
                    dataSource={report["product"]}
                    columns={columns}
                    summary={() => (
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>
                          Итого:
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>
                          <span className="column-content-font">
                            {calculateTotal(report["product"], "totalProduced")}{" "}
                            кг
                          </span>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2}>
                          <span className="column-content-font">
                            {calculateTotal(report["product"], "totalSold")} кг
                          </span>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    )}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Сырьё" key="rawMaterial">
                  <Table
                    pagination={false}
                    dataSource={report["rawMaterial"]}
                    columns={columns}
                    summary={() => (
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>
                          Итоги:
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>
                          <span className="column-content-font">
                            {calculateTotal(
                              report["rawMaterial"],
                              "totalProcessed"
                            )}{" "}
                            кг
                          </span>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2}>
                          <span className="column-content-font">
                            {calculateTotal(
                              report["rawMaterial"],
                              "totalBought"
                            )}{" "}
                            кг
                          </span>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    )}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Зарплаты" key="salaries">
                  <Table
                    pagination={false}
                    dataSource={report["salaries"]}
                    columns={salaryColumns}
                    summary={() => (
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>
                          Итоги:
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>
                          <span className="column-content-font">
                            {calculateTotal(
                              report["salaries"],
                              "totalProfit"
                            ).toFixed(2)}{" "}
                            руб
                          </span>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    )}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Общий отчет" key="generalReport">
                  <div>
                    <p>
                      <strong>Общее количество произведенной продукции:</strong>{" "}
                      {totalProduced} кг
                    </p>
                    <p>
                      <strong>Общее количество проданной продукции:</strong>{" "}
                      {totalSold} кг
                    </p>
                    <p>
                      <strong>Общая заработная плата:</strong>{" "}
                      {totalEarnedSalary.toFixed(2)} руб
                    </p>
                    <p>
                      <strong>Средняя цена закупки сырья:</strong>{" "}
                      {middlePriceRawMaterial.toFixed(2)} руб
                    </p>
                    <p>
                      <strong>Средняя цена продажи продукции:</strong>{" "}
                      {middlePriceSoldProducts.toFixed(2)} руб
                    </p>
                  </div>
                </Tabs.TabPane>
              </Tabs>
            </div>
          </Modal>
        )}
      </Spin>
    </div>
  );
};
