import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Button,
  message,
  InputNumber,
  Space,
  Typography,
  Radio,
  Spin,
} from "antd";

import DatePicker from "src/common/components/DatePicker";
import BackRouteButton from "src/common/components/BackRouteButton";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import "./index.css";

const { Option } = Select;
const { Title, Text } = Typography;

export default ({ createShift, profile, counteragents, storehouse }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [instances, setInstances] = useState(form.getFieldValue("instances"));

  const [products, setProducts] = useState([]);

  const onFinish = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;
    const { totalWeight, totalSum } = getTotalWeightAndSum(values.instances);
    values.totalWeight = totalWeight;
    values.totalSum = totalSum;

    if (values.instances.length === 0) {
      message.error("Добавьте хотя бы одну позицию!");
      return;
    }

    values.instances = values.instances.map((instance) => {
      const { _id, title } =
        products.find((inst) => inst._id === instance.id) || {};
      return { id: _id, title, weight: instance.weight };
    });

    const workerRoles = [
      "senior",
      "junior1",
      "junior2",
      "junior3",
      "junior4",
      "junior5",
      "junior6",
      "porter1",
      "porter2",
      "porter3",
      "porter4",
      "porter5",
      "porter6",
      "loader",
      "helperOfTechnologist",
      "grainstoreWorker1",
      "grainstoreWorker2",
      "packer",
    ];
    values.workers = workerRoles.reduce((workers, role) => {
      if (values[role]) {
        const { _id, firstname, lastname, additionalName } =
          counteragents.find(
            (counteragent) => counteragent._id === values[role]
          ) || {};
        workers[role] = { id: _id, firstname, lastname, additionalName };
      }
      return workers;
    }, {});

    const result = await createShift(values);
    if (result.success) {
      form.resetFields();
      message.success("Смена успешно создана!");
      history.goBack();
    } else {
      message.error(result.message || "Не удалось создать смену!");
      setLoading(false);
    }
  };

  const updateTotalWeightAndSum = () => {
    const instancesList = form.getFieldValue("instances");
    const { totalWeight, totalSum } = getTotalWeightAndSum(instancesList);
    form.setFieldsValue({
      totalWeight,
      totalSum,
    });
  };

  const getTotalWeightAndSum = (instancesList) => {
    return (instancesList || []).reduce(
      (acc, item) => {
        const weight = parseFloat(item.weight) || 0;
        const price = parseFloat(item.price) || 0;
        acc.totalWeight += weight;
        acc.totalSum += Number((weight * price).toFixed(2));
        return acc;
      },
      { totalWeight: 0, totalSum: 0 }
    );
  };

  const handleCounteragentFilter = (input, option) => {
    const counteragent = counteragents.find((ca) => ca._id === option.value);

    const counteragentName = `${counteragent.lastname || ""} ${
      counteragent.firstname || ""
    } ${counteragent.additionalName || ""} ${counteragent.firm || ""}`;

    return counteragentName.toLowerCase().includes(input.toLowerCase());
  };

  const handleProductFilter = (input, option) => {
    const product = products.find((p) => p._id === option.value);

    return product.title.toLowerCase().includes(input.toLowerCase());
  };

  const handleInstanceSelect = (value, field) => {
    const instancesList = form.getFieldValue("instances");

    const isDuplicate = instancesList.some(
      (instance) =>
        instance.id === value &&
        instance !== form.getFieldValue(["instances", field.name])
    );

    if (isDuplicate) {
      message.error("Данный вид продукции уже добавлен в список.");
      form.setFieldsValue({
        instances: instancesList.map((instance, index) =>
          index === field.name
            ? {
                ...instance,
                id: null,
                weight: null,
                sizeOfPack: null,
              }
            : instance
        ),
      });
    }
  };

  const renderInstancesList = (fields, operation) => {
    return (
      <>
        <Title level={5}>Список произведённой продукции:</Title>
        {fields.map((field, index) => (
          <Space.Compact block size="small" key={`${field.name}-${index}`}>
            <Form.Item
              {...field}
              name={[field.name, "id"]}
              key={[field.key, "id"]}
              style={{ minWidth: "50%", margin: "0px" }}
              rules={[
                {
                  required: true,
                  message: "Выберите продукт",
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Выберите продукт"
                filterOption={(input, option) =>
                  handleProductFilter(input, option, products)
                }
                onSelect={(value) => handleInstanceSelect(value, field)}
              >
                {products.map((instance) => (
                  <Option key={instance._id} value={instance._id}>
                    <h4 className="option-title-font">{instance.title}</h4>
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              {...field}
              style={{ width: "30%", margin: "0px" }}
              name={[field.name, "weight"]}
              key={[field.key, "weight"]}
              rules={[
                {
                  required: true,
                  message: "Введите вес",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Вес"
                onChange={updateTotalWeightAndSum}
              />
            </Form.Item>
            <Form.Item style={{ margin: "0px" }} {...field}>
              <MinusCircleOutlined
                style={{ marginLeft: "4px", color: "#ff4d4f" }}
                onClick={() => operation.remove(field.name)}
              />
            </Form.Item>
          </Space.Compact>
        ))}
        <Button
          block
          size="small"
          type="dashed"
          onClick={() => operation.add()}
          icon={<PlusOutlined />}
        >
          Добавить продукт
        </Button>
      </>
    );
  };

  useEffect(() => {
    if (!counteragents) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [counteragents]);

  useEffect(() => {
    if (storehouse) {
      let deepCopyOfStorehouse = JSON.parse(JSON.stringify(storehouse));

      var products = deepCopyOfStorehouse.productsCategories.flatMap(
        (category) => category.instances || []
      );

      for (var [index, product] of products.entries()) {
        product.title =
          product.title.replace(
            /пшеничная|марка|пшеничные|Хлебопекарная/g,
            ""
          ) +
          " " +
          product.sizeOfPack +
          "кг";

        products[index] = product;
      }

      const customSort = (a, b) => {
        const keywordsOrder = [
          "в/с 50",
          "в/с",
          "1/с",
          "2/с",
          "манная",
          "отруби",
        ];

        const titleA = a.title.toLowerCase();
        const titleB = b.title.toLowerCase();

        const includesBothA = titleA.includes("в/с") && titleA.includes("50");
        const includesBothB = titleB.includes("в/с") && titleB.includes("50");

        if (includesBothA && !includesBothB) {
          return -1;
        } else if (!includesBothA && includesBothB) {
          return 1;
        }

        if (titleA.includes("в/с 50") && !titleB.includes("в/с 50")) {
          return -1;
        } else if (!titleA.includes("в/с 50") && titleB.includes("в/с 50")) {
          return 1;
        }

        const indexA = keywordsOrder.findIndex((keyword) =>
          titleA.includes(keyword)
        );
        const indexB = keywordsOrder.findIndex((keyword) =>
          titleB.includes(keyword)
        );

        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB;
        }

        return titleA.localeCompare(titleB);
      };
      products.sort(customSort);

      setProducts(products);
    }
  }, [storehouse]);

  useEffect(() => {
    setInstances(form.getFieldValue("instances"));
  }, [form]);

  useEffect(() => {
    const calculations = getTotalWeightAndSum(instances);
    form.setFieldsValue({
      totalWeight: calculations.totalWeight,
      totalSum: calculations.totalSum,
    });
  }, [instances]);

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <BackRouteButton />
        <h4 className="common-title--large">Записать смену</h4>
        <Form
          requiredMark={false}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label="Дата:"
            name="date"
            rules={[
              {
                required: true,
                message: "Пожалуйста, выберите дату смены!",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="period"
            label="Период смены:"
            rules={[
              {
                required: true,
                message: "Пожалуйста, выберите период смены!",
              },
            ]}
          >
            <Radio.Group>
              <Radio value="day">День 8:00 - 20:00</Radio>
              <Radio value="night">Ночь 20:00 - 8:00 </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="repairTime"
            label="Время на ремонт(если был)колличество часов:"
          >
            <InputNumber
              size="small"
              style={{
                width: "10%",
              }}
            />
          </Form.Item>

          <Form.Item
            label="Старший смены:"
            name="senior"
            rules={[
              {
                required: true,
                message: "Пожалуйста, выберите старшего смены!",
              },
            ]}
          >
            <Select
              showSearch
              size="small"
              placeholder="Выберите старшего смены"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "работник цеха" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item
            label="Младший смены №1:"
            name="junior1"
            rules={[
              {
                required: true,
                message: "Пожалуйста, выберите младшего смены!",
              },
            ]}
          >
            <Select
              showSearch
              size="small"
              placeholder="Выберите младшего смены"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "работник цеха" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item
            label="Младший смены №2:"
            name="junior2"
            rules={[
              {
                required: true,
                message: "Пожалуйста, выберите младшего смены!",
              },
            ]}
          >
            <Select
              showSearch
              size="small"
              placeholder="Выберите младшего смены"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "работник цеха" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Младший смены №3:" name="junior3">
            <Select
              showSearch
              size="small"
              placeholder="Выберите младшего смены"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "работник цеха" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Младший смены №4:" name="junior4">
            <Select
              showSearch
              size="small"
              placeholder="Выберите младшего смены"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "работник цеха" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Младший смены №5:" name="junior5">
            <Select
              showSearch
              size="small"
              placeholder="Выберите младшего смены"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "работник цеха" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Младший смены №6:" name="junior6">
            <Select
              showSearch
              size="small"
              placeholder="Выберите младшего смены"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "работник цеха" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>

          <Form.Item label="Грузчик№1:" name="porter1">
            <Select
              showSearch
              size="small"
              placeholder="Выберите грузчика"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "работник цеха" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Грузчик№2:" name="porter2">
            <Select
              showSearch
              size="small"
              placeholder="Выберите грузчика"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "работник цеха" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Грузчик№3:" name="porter3">
            <Select
              showSearch
              size="small"
              placeholder="Выберите грузчика"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "работник цеха" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Грузчик№4:" name="porter4">
            <Select
              showSearch
              size="small"
              placeholder="Выберите грузчика"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "работник цеха" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Грузчик№5:" name="porter5">
            <Select
              showSearch
              size="small"
              placeholder="Выберите грузчика"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "работник цеха" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Грузчик№6:" name="porter6">
            <Select
              showSearch
              size="small"
              placeholder="Выберите грузчика"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "работник цеха" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Карщик:" name="loader">
            <Select
              showSearch
              size="small"
              placeholder="Выберите карщика"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "работник цеха" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Помощник технолога:" name="helperOfTechnologist">
            <Select
              showSearch
              size="small"
              placeholder="Выберите помощника технолога"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "работник цеха" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Работник зерносклада №1:" name="grainstoreWorker1">
            <Select
              showSearch
              size="small"
              placeholder="Выберите работника зерносклада"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "работник цеха" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Работник зерносклада №2:" name="grainstoreWorker2">
            <Select
              showSearch
              size="small"
              placeholder="Выберите работника зерносклада"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "работник цеха" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>
          <Form.Item label="Упаковщик:" name="packer">
            <Select
              showSearch
              size="small"
              placeholder="Выберите упаковщика"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map(
                  (counteragent) =>
                    counteragent.type === "работник цеха" && (
                      <Option key={counteragent._id} value={counteragent._id}>
                        <h4 className="option-title-font">
                          {`${counteragent.lastname || ""} ${
                            counteragent.firstname || ""
                          } ${counteragent.additionalName || ""} ${
                            counteragent.firm || ""
                          }`}
                        </h4>
                      </Option>
                    )
                )}
            </Select>
          </Form.Item>

          <Form.List
            initialValue={[
              {
                id: null,
                weight: null,
                price: null,
              },
            ]}
            name="instances"
          >
            {(fields, operation) => renderInstancesList(fields, operation)}
          </Form.List>

          <Space.Compact
            block
            size="small"
            style={{
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text>Общий вес</Text>
              <Form.Item name="totalWeight">
                <InputNumber readOnly />
              </Form.Item>
            </div>
          </Space.Compact>

          <Form.Item label="Комментарий" name="comment">
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Button type="primary" block size="medium" htmlType="submit">
              Создать
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
