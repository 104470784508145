import React from "react";
import { Link, useLocation } from "react-router-dom";

import "./index.css";
import CounterSVG from "src/assets/icons/CounterSVG.svg";
import OrdersSVG from "src/assets/icons/OrdersSVG.svg";
import PeopleSVG from "src/assets/icons/PeopleSVG.svg";
import ProcessingSVG from "src/assets/icons/ProcessingSVG.svg";
import StorehouseSVG from "src/assets/icons/StorehouseSVG.svg";

export default ({ profile }) => {
  let location = useLocation();

  const isActive = (base) => location.pathname.startsWith(base);

  return (
    <div className="layout__dashlinks-container">
      <Link
        className={`layout__dashlink ${
          isActive("/storehouse") ? "layout__dashlink--checked" : ""
        }`}
        to="/storehouse"
      >
        <StorehouseSVG
          className={`layout__dashlink-icon ${
            isActive("/storehouse") ? "layout__dashlink-icon--checked" : ""
          }`}
        />
        <h3 className="layout__dashlink__title">Склад</h3>
      </Link>

      <Link
        className={`layout__dashlink ${
          isActive("/processing") ? "layout__dashlink--checked" : ""
        }`}
        to="/processing"
      >
        <ProcessingSVG
          className={`layout__dashlink-icon ${
            isActive("/processing") ? "layout__dashlink-icon--checked" : ""
          }`}
        />
        <h3 className="layout__dashlink__title">Производство</h3>
      </Link>

      <Link
        className={`layout__dashlink ${
          isActive("/counteragents") ? "layout__dashlink--checked" : ""
        }`}
        to="/counteragents"
      >
        <PeopleSVG
          className={`layout__dashlink-icon ${
            isActive("/counteragents") ? "layout__dashlink-icon--checked" : ""
          }`}
        />
        <h3 className="layout__dashlink__title">Контрагенты</h3>
      </Link>

      <Link
        className={`layout__dashlink ${
          isActive("/orders") ? "layout__dashlink--checked" : ""
        }`}
        to="/orders"
      >
        <OrdersSVG
          className={`layout__dashlink-icon ${
            isActive("/orders") ? "layout__dashlink-icon--checked" : ""
          }`}
        />

        <h3 className="layout__dashlink__title">Заказы</h3>
      </Link>

      <Link
        className={`layout__dashlink ${
          isActive("/counters") ? "layout__dashlink--checked" : ""
        }`}
        to="/counters"
      >
        <CounterSVG
          className={`layout__dashlink-icon ${
            isActive("/counters") ? "layout__dashlink-icon--checked" : ""
          }`}
        />
        <h3 className="layout__dashlink__title">Финансы</h3>
      </Link>
    </div>
  );
};
