import React, { useRef, useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Select, Space } from "antd";

export default ({ listOfOptions, placeholder, onChange }) => {
  let index = 0;
  const [options, setOptions] = useState(false);
  const [name, setName] = useState("");
  const inputRef = useRef(null);
  const onNameChange = (event) => {
    setName(event.target.value);
  };
  const addItem = (e) => {
    e.preventDefault();
    setOptions([...options, name || `Новое название ${index++}`]);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  useEffect(() => {
    setOptions(listOfOptions);
  }, []);
  if (!options) return null;
  return (
    <Select
      style={{ width: "100%" }}
      size="small"
      onChange={onChange}
      optionFilterProp="children"
      placeholder={placeholder}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider
            style={{
              margin: "8px 0",
            }}
          />
          <Space
            style={{
              padding: "0 8px 4px",
            }}
          >
            <Input
              placeholder="Пожалуйста, введите свой вариант"
              ref={inputRef}
              value={name}
              onChange={onNameChange}
            />
            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
              Добавить свой
            </Button>
          </Space>
        </>
      )}
      options={options.map((option) => ({
        label: option,
        value: option,
      }))}
    />
  );
};
