import { connect } from "react-redux";
import ShowCounteragentShiftsScreen from "./ShowCounteragentShiftsScreen";
import { cancelShift } from "src/processing/redux/actions";
import { getCounteragentShifts } from "src/counteragents/redux/actions";

export default connect(
  (state) => {
    return {
      counteragentShifts: state.counteragents.counteragentShifts,
      profile: state.auth.profile,
      selectedCounteragent: state.counteragents.selectedCounteragent,
    };
  },
  {
    cancelShift,
    getCounteragentShifts,
  }
)(ShowCounteragentShiftsScreen);
