import { connect } from "react-redux";
import PaymentFromCounterScreen from "./PaymentFromCounterScreen";
import { paymentFromCounter } from "src/counters/redux/actions";

export default connect(
  (state) => {
    return {
      profile: state.auth.profile,
      counters: state.counters.counters,
    };
  },
  { paymentFromCounter }
)(PaymentFromCounterScreen);
