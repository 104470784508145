import { combineReducers } from "redux";

import auth from "src/auth/redux/reducer";
import storehouse from "src/storehouse/redux/reducer";
import counteragents from "src/counteragents/redux/reducer";
import counters from "src/counters/redux/reducer";
import processing from "src/processing/redux/reducer";
import orders from "src/orders/redux/reducer";

const rootReducer = combineReducers({
  auth,
  storehouse,
  counteragents,
  counters,
  processing,
  orders,
});

export default rootReducer;
