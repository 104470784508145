import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Tabs, Table, message, Button, Space, Modal, Spin } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import BackRouteButton from "src/common/components/BackRouteButton";

export default ({
  removeStorehouseInstance,
  storehouse,
  profile,
  recountStorehouse,
}) => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [productInstances, setProductInstances] = useState(false);
  const [rawMaterialInstances, setRawMaterialInstances] = useState(false);

  const handleEditInstance = async (instance) => {
    history.push({
      pathname: `/storehouse/settings/instance/edit`,
      state: { instance },
    });
  };
  const handleWriteOffInstance = async (instance) => {
    history.push({
      pathname: `/storehouse/settings/instance/write_off`,
      state: { instance },
    });
  };

  const handleRecount = async () => {
    setLoading(true);
    var result = await recountStorehouse();

    if (result.success) {
      message.success("Склад успешно пересчитан!");
      setLoading(false);
    } else {
      message.error(result.message || "Не удалось пересчитать склад!");
      setLoading(false);
    }
  };

  const handleDeleteInstance = async (values) => {
    setLoading(true);

    values.creatorId = profile._id;
    var result = await removeStorehouseInstance(values);

    if (result.success) {
      message.success("Вид продукции/сырья успешно удалён!");
    } else {
      message.error(result.message || "Не удалось удалить!");
      setLoading(false);
    }
  };

  const showConfirm = (category) => {
    Modal.confirm({
      title: "Вы уверены, что хотите удалить этот вид продукции/сырья?",
      icon: <ExclamationCircleOutlined />,
      content: "Это действие не может быть отменено.",
      okText: "Да, удалить",
      okType: "danger",
      cancelText: "Отмена",
      onOk() {
        handleDeleteInstance(category);
      },
      onCancel() {
        console.log("Cancelled");
      },
    });
  };

  const renderInstanceTable = (categories) => {
    const columns = [
      {
        title: <span className="column-title-font">Наименование</span>,
        dataIndex: "title",
        key: "title",
        render: (text, record) => (
          <span className="column-content-font">
            {record.title} / {record.sizeOfPack}кг
          </span>
        ),
      },
      {
        title: "Действия",
        key: "action",
        render: (text, record) => (
          <Space>
            <Button
              className="common-table-button"
              type="link"
              size="small"
              icon={<EditOutlined />}
              onClick={() => handleEditInstance(record)}
            />
            <Button
              className="common-table-button"
              type="link"
              size="small"
              icon={<FileExcelOutlined />}
              onClick={() => handleWriteOffInstance(record)}
            />
            <Button
              className="common-table-button"
              type="link"
              size="small"
              icon={<DeleteOutlined />}
              onClick={() => showConfirm(record)}
            />
          </Space>
        ),
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={categories}
        rowKey="_id"
        loading={loading}
        pagination={false}
      />
    );
  };

  const items = [
    {
      key: "1",
      label: "Продукты",
      children: renderInstanceTable(productInstances),
    },
    {
      key: "2",
      label: "Сырье",
      children: renderInstanceTable(rawMaterialInstances),
    },
  ];

  async function prepareInstances(storehouse) {
    var productInstances = [];
    var rawMaterialInstances = [];
    for (var productCategory of storehouse.productsCategories) {
      if (!productCategory.instances) continue;
      for (var productInstance of productCategory.instances) {
        productInstances.push(productInstance);
      }
    }
    for (var rawMaterialCategory of storehouse.rawMaterialsCategories) {
      if (!rawMaterialCategory.instances) continue;
      for (var rawMaterialInstance of rawMaterialCategory.instances) {
        rawMaterialInstances.push(rawMaterialInstance);
      }
    }
    setProductInstances(productInstances);
    setRawMaterialInstances(rawMaterialInstances);
  }

  useEffect(() => {
    if (!storehouse) {
      setLoading(true);
    } else {
      prepareInstances(storehouse);
      setLoading(false);
    }
  }, [storehouse]);

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <BackRouteButton />
        <h4 className="common-title--large">Все виды продукции/сырья</h4>
        <Tabs defaultActiveKey="1" items={items}></Tabs>
        <Space
          direction="vertical"
          style={{
            width: "100%",
          }}
        >
          <Button type="primary" onClick={handleRecount} block>
            Пересчитать остатки
          </Button>
        </Space>
      </Spin>
    </div>
  );
};
