import React, { useState } from "react";
import { Form, Input, Button, message, Spin } from "antd";
import { useLocation } from "react-router-dom";
import BackRouteButton from "src/common/components/BackRouteButton";

export default ({ writeOffStorehouseInstance, profile }) => {
  const location = useLocation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const instance = location.state.instance;

  form.setFieldsValue({
    title: instance.title,
    totalAvailable: instance.weight.totalAvailable,
  });

  const onFinish = async (values) => {
    setLoading(true);

    values._id = instance._id;
    values.creatorId = profile._id;
    var result = await writeOffStorehouseInstance(values);

    if (result.success) {
      message.success("Списание произведено!!");
    } else {
      message.error(result.message || "Не удалось списать!");
      setLoading(false);
    }
  };

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <BackRouteButton />
        <h4 className="common-title--large">
          Cписать {instance.type === "product" ? "продукцию" : "сырьё"}
        </h4>
        <Form onFinish={onFinish} requiredMark={false} form={form}>
          <Form.Item label="Название" disabled={true} name="title">
            <Input />
          </Form.Item>
          <Form.Item
            label="Всего доступно"
            disabled={true}
            name="totalAvailable"
          >
            <Input />
          </Form.Item>
          <Form.Item
            requiredMark="hidden"
            label="Сколько списать"
            name="weight"
            rules={[
              {
                required: true,
                message: "Введите колличество списания!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Списать
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
