import React, { useState, useEffect } from "react";
import commonFunc from "src/utils/commonFunc";
import BackRouteButton from "src/common/components/BackRouteButton";

import {
  Table,
  Card,
  Modal,
  Button,
  Space,
  Input,
  Radio,
  message,
  Spin,
} from "antd";
import {
  EyeOutlined,
  FieldNumberOutlined,
  CalendarOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  CheckSquareOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const { formatDate } = commonFunc;

export default ({
  counteragentPayments,
  profile,
  cancelPayment,
  verifyPayment,
  selectedCounteragent,
  getCounteragentPayments,
}) => {
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [filter, setFilter] = useState("all");
  const [dataSource, setDataSourse] = useState([]);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setFilter(value);
  };

  const filterPayments = (filter, counteragentPayments, searchValue) => {
    let filteredData = counteragentPayments || [];

    for (var payment of counteragentPayments) {
      if (payment.counter1) {
        payment.income = payment.from !== payment.counter1.id;
      } else {
        payment.income = null;
      }
    }

    if (searchValue) {
      filteredData = filteredData.filter((payment) => {
        const searchValueLower = searchValue.toLowerCase();
        const searchNumber = parseFloat(searchValue);

        return (
          payment.counteragent?.firstname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          payment.counteragent?.lastname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          payment.counteragent?.additionalName
            ?.toLowerCase()
            .includes(searchValueLower) ||
          payment.counteragent?.firm
            ?.toLowerCase()
            .includes(searchValueLower) ||
          payment.serial === searchNumber ||
          payment.type.toLowerCase().includes(searchValueLower) ||
          payment.title.toLowerCase().includes(searchValueLower) ||
          payment.order?.serial === searchNumber ||
          payment.receiptOrder?.serial === searchNumber ||
          payment.sum === searchNumber
        );
      });
    }

    switch (filter) {
      case "notVerified":
        return filteredData.filter(
          (payment) => !payment.verified && !payment.cancelled
        );
      case "verified":
        return filteredData.filter((payment) => payment.verified);
      case "cancelled":
        return filteredData.filter((payment) => payment.cancelled);
      case "income":
        return filteredData.filter((payment) => payment.income);
      case "outcome":
        return filteredData.filter((payment) => !payment.income);
      case "addOnBalance":
        return filteredData.filter((payment) => payment.income === null);
      default:
        return filteredData;
    }
  };

  const prepareDataSource = (data) => {
    const sortedData = [...data].sort((a, b) => b.serial - a.serial);

    return sortedData.map((payment, index) => ({
      ...payment,
      key: payment._id,
      date: formatDate(payment.date).slice(0, 20),
      income: payment.counter1
        ? payment.to === payment.counter1.id
          ? true
          : false
        : null,
      counteragentName: `${payment.counteragent?.firm || ""} ${
        payment.counteragent?.lastname || ""
      } ${payment.counteragent?.firstname || ""}
      ${payment.counteragent?.additionalName || ""}`,
    }));
  };

  const handleCancelPayment = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;
    var result = await cancelPayment(values);

    if (result.success) {
      await getCounteragentPayments(selectedCounteragent);
      message.success("Операция успешно отменена!");
      setLoading(false);
    } else {
      message.error(result.message || "Не удалось отменить!");
      setLoading(false);
    }
  };
  const handleVerify = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;
    var result = await verifyPayment(values);

    if (result.success) {
      await getCounteragentPayments(selectedCounteragent);
      message.success("Платёж подтвержден!");
      setLoading(false);
    } else {
      message.error(result.message || "Не удалось подтвердить!");
      setLoading(false);
    }
  };

  const showConfirmCancel = (order) => {
    Modal.confirm({
      title: "Вы уверены, что хотите отменить этот платеж?",
      icon: <ExclamationCircleOutlined />,
      content: "Это действие не может быть отменено.",
      okText: "Да, отменить",
      okType: "danger",
      cancelText: "Отмена",
      onOk() {
        handleCancelPayment(order);
      },
      onCancel() {
        console.log("Cancelled");
      },
    });
  };
  const showConfirmVerifiing = (payment) => {
    Modal.confirm({
      title: "Вы уверены, что хотите верифицировать платеж?",
      icon: <ExclamationCircleOutlined />,
      content: 'Это действие изменяет статус платёжа на "подтвержденный".',
      okText: "Да, подтвердить",
      okType: "danger",
      cancelText: "Отмена",
      onOk() {
        handleVerify(payment);
      },
      onCancel() {
        console.log("Cancelled");
      },
    });
  };

  const showModal = (payment) => {
    setSelectedPayment(payment);
    setIsModalVisible(true);
  };

  const PaymentModalContent = () => {
    if (!selectedPayment) {
      return null;
    }

    const {
      date,
      serial,
      counteragentName,
      title,
      type,
      sum,
      from,
      comment,
      cancelled,
      verified,
      counter1,
      order,
      receiptOrder,
    } = selectedPayment;

    return (
      <Card
        size="small"
        title={
          <span className="column-title-font">{`Денежная операция №${serial}`}</span>
        }
        extra={
          <span className="column-title-font">
            Произведено: {cancelled ? "ОТМЕНЕНА" : date.slice(0, 20)}
          </span>
        }
      >
        <div className="common-modal__info-block">
          <div className="common-modal__info-container">
            <div className="common-modal__info-container">
              <span className="column-title-font">Операция:</span>
              <span className="column-content-font">{title}</span>
            </div>
            {counteragentName && counteragentName.trim() !== "" && (
              <div>
                <span className="column-title-font">Контрагент:</span>
                <span className="column-content-font">{counteragentName}</span>
              </div>
            )}
            {order && (
              <div>
                <span className="column-title-font">Заказ:</span>
                <span className="column-content-font">№ {order.serial}</span>
              </div>
            )}
            {receiptOrder && (
              <div>
                <span className="column-title-font">Приходный ордер:</span>
                <span className="column-content-font">
                  № {receiptOrder.serial}
                </span>
              </div>
            )}
            {counter1 ? (
              counter1.id === from ? (
                <div className="common-modal__info-container">
                  <span className="column-title-font">Выплата из:</span>
                  <span className="column-content-font">{counter1.title}</span>
                </div>
              ) : (
                <div className="common-modal__info-container">
                  <span className="column-title-font">Платеж в:</span>
                  <span className="column-content-font">{counter1.title}</span>
                </div>
              )
            ) : (
              <div className="common-modal__info-container">
                <span className="column-title-font">Тип операции:</span>
                <span className="column-content-font">
                  начисление на баланс контрагента
                </span>
              </div>
            )}
          </div>

          <div className="common-modal__info-container">
            <span className="column-title-font">Сумма:</span>
            <span className="column-content-font">{sum} руб.</span>
          </div>
          <div className="common-modal__info-container">
            <span className="column-title-font">Способ:</span>
            <span className="column-content-font">{type}</span>
          </div>
          <div className="common-modal__info-container">
            <span className="column-title-font">Подтверждено:</span>
            {verified ? (
              <span className="column-title-font" style={{ color: "green" }}>
                Да
              </span>
            ) : (
              <span className="column-content-font" style={{ color: "red" }}>
                Нет
              </span>
            )}
          </div>
          {comment && (
            <div className="common-modal__info-container">
              <span className="column-title-font">Комментарий:</span>
              <span className="column-content-font">{comment}</span>
            </div>
          )}
        </div>
      </Card>
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: (
        <div>
          <FieldNumberOutlined /> <CalendarOutlined />
        </div>
      ),
      dataIndex: "serial",
      key: "serial",
      render: (_, record) => (
        <span className="column-content-font">
          {record.serial} / {record.date}
        </span>
      ),
    },
    {
      title: <span className="column-content-font">+/-</span>,
      dataIndex: "serial",
      key: "serial",
      render: (_, record) => (
        <span className="column-content-font">
          {record.income === null ? (
            <PlusOutlined style={{ color: "green", fontSize: "15px" }} />
          ) : record.income ? (
            <ArrowLeftOutlined style={{ color: "green", fontSize: "15px" }} />
          ) : (
            <ArrowRightOutlined style={{ color: "blue", fontSize: "15px" }} />
          )}
        </span>
      ),
    },
    {
      title: <span className="table-title-font">Название</span>,
      dataIndex: "title",
      key: "title",
      render: (text) => <span className="column-content-font">{text}</span>,
    },
    {
      title: <span className="table-title-font">Сумма</span>,
      dataIndex: "sum",
      key: "sum",
      render: (text, record) =>
        record.cancelled ? (
          <span className="table-title-font">ОТМЕНЁН</span>
        ) : (
          <span className="column-content-font">{text} р.</span>
        ),
    },
    {
      title: <span className="table-title-font">Действия</span>,
      key: "action",
      render: (text, record) => (
        <Space>
          <Button
            disabled={record.verified || record.cancelled}
            className="common-table-button"
            type="link"
            size="small"
            icon={
              <CheckSquareOutlined
                style={{
                  color: record.cancelled
                    ? "inherit"
                    : record.verified
                    ? "green"
                    : "red",
                }}
              />
            }
            onClick={() =>
              profile.rang === "admin" ? showConfirmVerifiing(record) : null
            }
          />
          <Button
            className="common-table-button"
            size="small"
            type="link"
            onClick={() => showModal(record)}
            icon={<EyeOutlined />}
          />
          <Button
            disabled={record.cancelled || record.verified}
            className="common-table-button"
            type="link"
            size="small"
            icon={<DeleteOutlined />}
            onClick={() => showConfirmCancel(record)}
            danger
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (!counteragentPayments) {
      setLoading(true);
      return;
    }

    const payments = filterPayments(filter, counteragentPayments, searchValue);

    const dataSource = prepareDataSource(payments);
    setDataSourse(dataSource);

    setLoading(false);
  }, [filter, counteragentPayments, searchValue]);

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <BackRouteButton />

        <span className="common-info-title">
          Все денежные операции контрагента: {selectedCounteragent.nameInfo}
        </span>

        <Input.Search
          placeholder="Поиск"
          onChange={handleSearchChange}
          style={{ marginBottom: 8 }}
          size="small"
        />
        <Radio.Group
          onChange={handleFilterChange}
          value={filter}
          style={{ marginBottom: 16 }}
          size="small"
        >
          <Radio.Button value="all">Все</Radio.Button>
          <Radio.Button value="income">Входящие</Radio.Button>
          <Radio.Button value="outcome">Исходящие</Radio.Button>
          <Radio.Button value="addOnBalance">Начисления</Radio.Button>
          <Radio.Button value="notVerified">Неподтвержденные</Radio.Button>
          <Radio.Button value="verified">Подтвержденные</Radio.Button>
          <Radio.Button value="cancelled">Отмененные</Radio.Button>
        </Radio.Group>
        <Table
          pagination={{ pageSize: 20 }}
          dataSource={dataSource}
          columns={columns}
        />
        <Modal
          open={isModalVisible}
          bodyStyle={{ marginTop: "20px" }}
          onCancel={handleCancel}
          footer={null}
        >
          <PaymentModalContent />
        </Modal>
      </Spin>
    </div>
  );
};
