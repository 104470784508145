import React, { useRef, useEffect, Fragment } from "react";
import { useReactToPrint } from "react-to-print";
import LogoSvg from "src/assets/icons/LogoSvg.svg";

import "./index.css";

export default ({ print, order, handlePrint }) => {
  if (!order) {
    return null;
  }
  const componentRef = useRef();
  const startPrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate()}.${
    currentDate.getMonth() + 1
  }.${currentDate.getFullYear()}`;

  const totalProductSum = order.instances.reduce(
    (sum, product) => sum + product.price * product.weight,
    0
  );

  const isDeliveryIncluded =
    order.totalSum === totalProductSum + order.deliveryCost;
  const totalSumForPrint = isDeliveryIncluded
    ? order.totalSum
    : +order.totalSum + +order.deliveryCost;

  const handleOk = () => {
    startPrint();
    handlePrint(false);
  };
  const style = print ? {} : { display: "none" };

  useEffect(() => {
    if (print) {
      handleOk();
    }
  }, [print]);
  return (
    <div className="invoice" style={style} ref={componentRef}>
      <div className="invoice__header">
        <h4 className="invoice__title">
          Товарная накладная №{order.serial} от {formattedDate}
        </h4>
        <LogoSvg
          style={{ width: "150px", height: "90px", fill: "#7d7371" }}
          className="layout__logo"
        />
      </div>
      <div className="invoice__info-line">
        <p className="invoice__light-header ">Поставщик:</p>

        <h5 className="invoice__semi-header">
          Динская Мельница &nbsp; &nbsp;ИП Брецкий В.Д. &nbsp;&nbsp;353200
          Краснодарский край&nbsp; &nbsp;ст.Динская ул. Новая д. 11&nbsp;&nbsp;
          +7 938-515-61-61
        </h5>
      </div>
      <div className="invoice__info-line">
        <p className="invoice__light-header ">Покупатель:</p>
        <h5 className="invoice__semi-header">
          {order.counteragent.firm &&
            `${order.counteragent.firm} ${`\u00A0\u00A0\u00A0\u00A0`}`}
          {order.counteragent.firstname}
          &nbsp;
          {order.counteragent.additionalName}
          &nbsp;
          {order.counteragent.lastname} &nbsp;&nbsp;{" "}
          {order.counteragent.address}&nbsp;&nbsp;
          {order.counteragent.phone}
        </h5>
      </div>
      <table className="added-products-list">
        <thead>
          <tr>
            <th className="product-char__title--print">Товар/Услуга</th>
            <th className="product-char__title--print">Вес/Штук</th>
            <th className="product-char__title--print">Цена</th>
            <th className="product-char__title--print">Стоимость</th>
          </tr>
        </thead>
        <tbody>
          {order.instances.map((product) => (
            <Fragment key={product.id}>
              <tr className="product-char-list">
                <td className="product-char--print">{product.title}</td>
                <td className="product-char--print">{product.weight} Кг</td>
                <td className="product-char--print">{product.price} Руб.</td>
                <td className="product-char--print">
                  {new Intl.NumberFormat().format(
                    product.price * product.weight
                  )}
                  &nbsp; Руб.
                </td>
              </tr>
            </Fragment>
          ))}

          {order.deliveryCost && order.deliveryCost > 0 && (
            <tr className="product-char-list">
              <td className="product-char--print">Доставка</td>
              <td className="product-char--print">-</td>
              <td className="product-char--print">-</td>
              <td className="product-char--print">{order.deliveryCost} Руб.</td>
            </tr>
          )}
        </tbody>
      </table>
      {order.driver && (
        <div className="invoice__info-line">
          <p className="invoice__light-header ">Водитель:</p>
          <h5 className="invoice__semi-header">
            {order.driver.firm &&
              `${order.driver.firm} ${`\u00A0\u00A0\u00A0\u00A0`}`}
            {order.driver.firstname}
            &nbsp;
            {order.driver.additionalName}
            &nbsp;
            {order.driver.lastname} &nbsp;&nbsp; {order.driver.address}
            &nbsp;&nbsp;
            {order.driver.phone}
          </h5>
        </div>
      )}

      <br />
      <div className="total-container--invoice">
        <h5 className="total__header--print">Общий вес:</h5>
        <h5 className="total__info--print">{order.totalWeight} Кг. </h5>
      </div>
      <div className="total-container--invoice">
        <h5 className="total__header--print">Итого:</h5>
        <h5 className="total__info--print">
          {totalSumForPrint}
          &nbsp; Руб.
        </h5>
      </div>

      <div
        className="invoice__info-line"
        style={{ borderTop: "solid 2px black", paddingTop: "30px" }}
      >
        <p className="invoice__light-header" style={{ width: "70px" }}>
          Отпустил:
        </p>
        <p className="sign-line">подпись</p>
        <p className="sign-line" style={{ width: "450px" }}>
          расшифровка подписи
        </p>
      </div>
      <div className="invoice__info-line">
        <p className="invoice__light-header" style={{ width: "70px" }}>
          Получил:
        </p>
        <p className="sign-line">подпись</p>
        <p className="sign-line" style={{ width: "450px" }}>
          расшифровка подписи
        </p>
      </div>
    </div>
  );
};
