import { connect } from "react-redux";
import ShowAllCountersScreen from "./ShowAllCountersScreen";
import { cancelPayment, verifyPayment } from "src/counters/redux/actions";

export default connect(
  (state) => {
    return {
      counters: state.counters.counters,
      profile: state.auth.profile,
    };
  },
  {
    cancelPayment,
    verifyPayment,
  }
)(ShowAllCountersScreen);
