import { connect } from "react-redux";
import EditAnalysisScreen from "./EditAnalysisScreen";
import { editAnalysis } from "src/processing/redux/actions";

export default connect(
  (state) => {
    return {
      profile: state.auth.profile,
      analyses: state.processing.analyses,
    };
  },
  { editAnalysis }
)(EditAnalysisScreen);
