import { connect } from "react-redux";
import ShowAllReceiptOrdersScreen from "./ShowAllReceiptOrdersScreen";
import { cancelReceiptOrder } from "src/storehouse/redux/actions";

export default connect(
  (state) => {
    return {
      receiptOrders: state.storehouse.receiptOrders,
      profile: state.auth.profile,
    };
  },
  {
    cancelReceiptOrder,
  }
)(ShowAllReceiptOrdersScreen);
