import React, { useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Button,
  message,
  InputNumber,
  Space,
  Typography,
  Spin,
  Checkbox,
} from "antd";
import dayjs from "dayjs";
import BackRouteButton from "src/common/components/BackRouteButton";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import DatePicker from "src/common/components/DatePicker";

import "./index.css";

const { Option } = Select;
const { Title, Text } = Typography;

export default ({ editOrder, profile, counteragents, storehouse, orders }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const [includeDelivery, setIncludeDelivery] = useState(false);
  const [selectedCounteragent, setSelectedCounteragent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState(false);
  const [products, setProducts] = useState([]);

  const onFinish = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;
    values._id = order._id;
    values.returns = order.returns;
    const { totalWeight, totalSum } = getTotalWeightAndSum(values.instances);

    values.totalWeight = totalWeight;
    values.totalSum = totalSum;

    const selectedCounteragent = counteragents.find(
      (counteragent) => counteragent._id === values.counteragent
    );
    values.counteragent = {
      firstname: selectedCounteragent.firstname,
      lastname: selectedCounteragent.lastname,
      additionalName: selectedCounteragent.additionalName,
      firm: selectedCounteragent.firm,
      id: selectedCounteragent._id,
    };

    if (values.driver) {
      const selectedDriver = counteragents.find(
        (counteragent) => counteragent._id === values.driver
      );
      values.driver = {
        firstname: selectedDriver.firstname,
        lastname: selectedDriver.lastname,
        additionalName: selectedDriver.additionalName,
        firm: selectedDriver.firm,
        id: selectedDriver._id,
      };
    }

    if (values.instances.length === 0) {
      message.error("Добавьте хотя бы одну позицию!");
      return;
    }

    var productsOriginal = storehouse.productsCategories.flatMap(
      (category) => category.instances || []
    );
    values.instances = values.instances.map((instance) => {
      const selectedInstance = productsOriginal.find(
        (inst) => inst._id === instance.id
      );
      return {
        id: selectedInstance._id,
        title:
          selectedInstance.title + " " + selectedInstance.sizeOfPack + "кг",
        weight: instance.weight,
        price: instance.price,
      };
    });

    const result = await editOrder(values);
    if (result.success) {
      form.resetFields();
      message.success("Заказ успешно обновлён!");
      history.goBack();
    } else {
      message.error(result.message || "Не удалось обновить заказ!");
      setLoading(false);
    }
  };

  const handleProductFilter = (input, option) => {
    const product = products.find((p) => p._id === option.value);

    return product.title.toLowerCase().includes(input.toLowerCase());
  };

  const handleCounteragentFilter = (input, option) => {
    const counteragent = counteragents.find((ca) => ca._id === option.value);

    const counteragentName = `${counteragent.lastname || ""} ${
      counteragent.firstname || ""
    } ${counteragent.additionalName || ""} ${counteragent.firm || ""}`;

    return counteragentName.toLowerCase().includes(input.toLowerCase());
  };

  const updateTotalWeightAndSum = () => {
    const instancesList = form.getFieldValue("instances");
    const { totalWeight, totalSum } = getTotalWeightAndSum(instancesList);
    form.setFieldsValue({
      totalWeight,
      totalSum,
    });
  };

  const getTotalWeightAndSum = (instancesList) => {
    const { totalWeight, totalSum } = (instancesList || []).reduce(
      (acc, item) => {
        const weight = parseFloat(item.weight) || 0;
        const price = parseFloat(item.price) || 0;
        acc.totalWeight += weight;
        acc.totalSum += Number((weight * price).toFixed(2));
        return acc;
      },
      { totalWeight: 0, totalSum: 0 }
    );

    if (includeDelivery) {
      const deliveryCost = Number(form.getFieldValue("deliveryCost") || 0);
      const totalSumWithDelivery = totalSum + deliveryCost;
      return { totalWeight, totalSum: totalSumWithDelivery };
    } else {
      return { totalWeight, totalSum };
    }
  };

  const handleInstanceSelect = (value, field) => {
    const instancesList = form.getFieldValue("instances");

    const isDuplicate = instancesList.some(
      (instance) =>
        instance.id === value &&
        instance !== form.getFieldValue(["instances", field.name])
    );

    if (isDuplicate) {
      message.error("Данный вид продукции уже добавлен в список.");
      form.setFieldsValue({
        instances: instancesList.map((instance, index) =>
          index === field.name
            ? {
                id: null,
                weight: null,
                price: null,
                totalAvailable: null,
              }
            : instance
        ),
      });
    } else {
      const selectedProduct = products.find((product) => product._id === value);
      if (selectedProduct) {
        form.setFieldsValue({
          instances: instancesList.map((instance, index) =>
            index === field.name
              ? {
                  ...instance,
                  totalAvailable: selectedProduct.weight.totalAvailable,
                }
              : instance
          ),
        });
      }
    }
  };

  const handleCounteragentSelect = (selectedValue) => {
    const selected = counteragents.find((ca) => ca._id === selectedValue);
    setSelectedCounteragent(selected);
    if (selected) {
      form.setFieldsValue({
        counteragentBalance: selected.balance,
      });
    }
  };

  const handleWeightChange = (value, name) => {
    const instances = form.getFieldValue("instances");
    const totalAvailable = form.getFieldValue([
      "instances",
      name,
      "totalAvailable",
    ]);

    if (value > totalAvailable) {
      form.setFieldsValue({
        instances: instances.map((instance, index) =>
          index === name
            ? {
                ...instance,
                weight: totalAvailable,
              }
            : instance
        ),
      });
    }
    updateTotalWeightAndSum();
  };

  const renderInstancesList = (fields, operation) => {
    return (
      <>
        <Title level={5}>Список товаров</Title>
        {fields.map((field, index) => (
          <div className="order-instance-container" key={field.key}>
            <Text style={{ width: "5%", margin: "0px" }} strong>
              {index + 1})
            </Text>
            <Space.Compact
              block
              size="small"
              key={field.key}
              direction="vertical"
            >
              <Space.Compact block size="small">
                <Form.Item
                  {...field}
                  name={[field.name, "id"]}
                  key={[field.key, "id"]}
                  style={{ width: "55%", margin: "0px" }}
                  rules={[
                    {
                      required: true,
                      message: "Выберите продукт",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Выберите продукт"
                    filterOption={handleProductFilter}
                    onSelect={(value) => handleInstanceSelect(value, field)}
                  >
                    {products.map((instance) => (
                      <Option key={instance._id} value={instance._id}>
                        <h4 className="option-title-font">{instance.title}</h4>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  {...field}
                  style={{ width: "30%", margin: "0px" }}
                  name={[field.name, "totalAvailable"]}
                  key={[field.key, "totalAvailable"]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="доступно кг"
                    readOnly
                    formatter={(value) => {
                      if (value) {
                        return `${value} кг`;
                      }
                    }}
                  />
                </Form.Item>
              </Space.Compact>
              <Space.Compact block size="small">
                <Form.Item
                  {...field}
                  style={{ width: "45%", margin: "0px" }}
                  name={[field.name, "weight"]}
                  key={[field.key, "weight"]}
                  rules={[
                    {
                      required: true,
                      message: "Введите вес",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Вес"
                    onChange={(value) => handleWeightChange(value, field.name)}
                    formatter={(value) => {
                      if (value) {
                        return `${value} кг`;
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: "45%", margin: "0px" }}
                  {...field}
                  name={[field.name, "price"]}
                  key={[field.key, "price"]}
                  rules={[
                    {
                      required: true,
                      message: "Введите цену",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Цена"
                    onChange={updateTotalWeightAndSum}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item
                  key={[field.name, "remove"]}
                  style={{ margin: "0px" }}
                  {...field}
                >
                  <MinusCircleOutlined
                    style={{ marginLeft: "4px", color: "#ff4d4f" }}
                    onClick={() => operation.remove(field.name)}
                  />
                </Form.Item>
              </Space.Compact>
            </Space.Compact>
          </div>
        ))}
        <Button
          block
          size="small"
          type="dashed"
          onClick={() => operation.add()}
          icon={<PlusOutlined />}
        >
          Добавить товар
        </Button>
      </>
    );
  };

  useEffect(() => {
    if (orders) {
      const id = location.state.order._id;

      for (var order of orders) {
        if (order._id === id) {
          setOrder(order);
          break;
        }
      }
    }
  }, [orders]);
  useEffect(() => {
    updateTotalWeightAndSum();
  }, [includeDelivery]);

  useEffect(() => {
    if (!counteragents) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [counteragents]);

  useEffect(() => {
    if (storehouse) {
      let deepCopyOfStorehouse = JSON.parse(JSON.stringify(storehouse));

      var products = deepCopyOfStorehouse.productsCategories.flatMap(
        (category) => category.instances || []
      );
      products = products.filter(
        (product) => product.weight.totalAvailable > 0
      );

      for (var [index, product] of products.entries()) {
        product.title =
          product.title.replace(
            /пшеничная|марка|пшеничные|Хлебопекарная/g,
            ""
          ) +
          " " +
          product.sizeOfPack +
          "кг";

        products[index] = product;
      }

      const customSort = (a, b) => {
        const keywordsOrder = [
          "в/с 50",
          "в/с",
          "1/с",
          "2/с",
          "манная",
          "отруби",
        ];

        const titleA = a.title.toLowerCase();
        const titleB = b.title.toLowerCase();

        const includesBothA = titleA.includes("в/с") && titleA.includes("50");
        const includesBothB = titleB.includes("в/с") && titleB.includes("50");

        if (includesBothA && !includesBothB) {
          return -1;
        } else if (!includesBothA && includesBothB) {
          return 1;
        }

        if (titleA.includes("в/с 50") && !titleB.includes("в/с 50")) {
          return -1;
        } else if (!titleA.includes("в/с 50") && titleB.includes("в/с 50")) {
          return 1;
        }

        const indexA = keywordsOrder.findIndex((keyword) =>
          titleA.includes(keyword)
        );
        const indexB = keywordsOrder.findIndex((keyword) =>
          titleB.includes(keyword)
        );

        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB;
        }

        return titleA.localeCompare(titleB);
      };
      products.sort(customSort);

      setProducts(products);
    }
  }, [storehouse]);

  useEffect(() => {
    if (!order) return;
    const dateValue = dayjs(order.date);

    const instancesWithDetails = order.instances.map((instance) => {
      const product = products.find((inst) => inst._id === instance.id);
      return {
        ...instance,
        instance: instance.id,
        totalAvailable: product.weight.totalAvailable,
      };
    });
    const selected = counteragents.find(
      (ca) => ca._id === order.counteragent.id
    );

    setSelectedCounteragent(order.counteragent);
    form.setFieldsValue({
      date: dateValue,
      counteragent: order.counteragent.id,
      instances: instancesWithDetails,
      totalWeight: order.totalWeight,
      totalSum: order.totalSum,
      comment: order.comment,
      driver: order.driver ? order.driver.id : null,
      deliveryCost: order.deliveryCost ? order.deliveryCost : null,
      deliveryAddress: order.deliveryAddress ? order.deliveryAddress : null,
      counteragentBalance: selected.balance,
    });
  }, [order]);

  return (
    <div className="common-container">
      <BackRouteButton />
      <h4 className="common-title--large">Редактировать заказ</h4>
      <Spin spinning={loading}>
        <Form
          requiredMark={false}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label="Дата заказа"
            name="date"
            rules={[
              {
                required: true,
                message: "Пожалуйста, выберите дату продажи!",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Покупатель"
            name="counteragent"
            rules={[
              {
                required: true,
                message: "Пожалуйста, выберите покупателя!",
              },
            ]}
          >
            <Select
              showSearch
              size="small"
              placeholder="Выберите покупателя"
              filterOption={handleCounteragentFilter}
              onSelect={handleCounteragentSelect}
            >
              {counteragents &&
                counteragents.map((counteragent) => (
                  <Option key={counteragent._id} value={counteragent._id}>
                    <h4 className="option-title-font">
                      {`${counteragent.lastname || ""} ${
                        counteragent.firstname || ""
                      } ${counteragent.additionalName || ""} ${
                        counteragent.firm || ""
                      }`}
                    </h4>
                  </Option>
                ))}
            </Select>
          </Form.Item>
          {selectedCounteragent && (
            <Form.Item label="Баланс покупателя" name="counteragentBalance">
              <InputNumber
                size="small"
                style={{
                  width: "40%",
                  color:
                    selectedCounteragent?.balance < 0
                      ? "red"
                      : selectedCounteragent?.balance > 0
                        ? "green"
                        : "inherit",
                }}
                value={
                  selectedCounteragent
                    ? `Баланс: ${selectedCounteragent.balance} руб.`
                    : ""
                }
                formatter={(value) => {
                  if (value) {
                    return `${value} руб`;
                  }
                }}
                readOnly
              />
            </Form.Item>
          )}

          <Form.List
            initialValue={[
              {
                instance: null,
                weight: null,
                price: null,
              },
            ]}
            name="instances"
          >
            {(fields, operation) => renderInstancesList(fields, operation)}
          </Form.List>

          <Space.Compact
            block
            size="small"
            style={{
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text>Общий вес</Text>
              <Form.Item name="totalWeight">
                <InputNumber readOnly />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text>Общая сумма</Text>
              <Form.Item name="totalSum">
                <InputNumber readOnly />
              </Form.Item>
            </div>
          </Space.Compact>
          <Form.Item label="Водитель(если есть)" name="driver">
            <Select
              showSearch
              size="small"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map((counteragent) => (
                  <Option key={counteragent._id} value={counteragent._id}>
                    <h4 className="option-title-font">
                      {`${counteragent.lastname || ""} ${
                        counteragent.firstname || ""
                      } ${counteragent.additionalName || ""} ${
                        counteragent.firm || ""
                      }`}
                    </h4>
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item label="Стоимость доставки(если есть)" name="deliveryCost">
            <InputNumber
              onChange={updateTotalWeightAndSum}
              style={{ width: "40%" }}
              size="small"
            />
          </Form.Item>
          <Form.Item name="includeDelivery">
            <Checkbox
              checked={includeDelivery}
              onChange={(e) => {
                setIncludeDelivery(e.target.checked);
              }}
              style={{ width: "40%" }}
              size="small"
            >
              Включить доставку в стоимость в заказа
            </Checkbox>
          </Form.Item>
          <Form.Item label="Адрес доставки(если есть)" name="deliveryAddress">
            <Input.TextArea />
          </Form.Item>

          <Form.Item label="Комментарий" name="comment">
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Button type="primary" block size="medium" htmlType="submit">
              Обновить
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
