import React, { useState, useEffect } from "react";
import { Form, Input, Radio, Button, message, Spin } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import BackRouteButton from "src/common/components/BackRouteButton";

export default ({ editCategory, profile }) => {
  const location = useLocation();
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);

  const category = location.state.category;

  const onFinish = async (values) => {
    setLoading(true);
    try {
      values._id = category._id;
      values.creatorId = profile._id;
      var result = await editCategory(values);
      if (result.success) {
        message.success("Категория успешно обновлена!");
        history.goBack();
      }
    } catch (error) {
      message.error(result.message || "Ошибка при обновлении категории!");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (category) {
      form.setFieldsValue({
        title: category.title,
        type: category.type,
      });
      setLoading(false);
    }
  }, [category, form]);
  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <BackRouteButton />
        <h4 className="common-title--large">Редактировать категорию</h4>
        <Form onFinish={onFinish} requiredMark={false} form={form}>
          <Form.Item
            label="Название категории"
            name="title"
            rules={[
              {
                required: true,
                message: "Пожалуйста введите название категории!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            requiredMark="hidden"
            label="Тип категории"
            name="type"
            rules={[
              { required: true, message: "Пожалуйста выберите тип категории!" },
            ]}
          >
            <Radio.Group>
              <Radio value="product">Продукт</Radio>
              <Radio value="rawMaterial">Сырьё</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Обновить категорию
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
