import { connect } from "react-redux";
import ShowAllShiftsScreen from "./ShowAllShiftsScreen";
import { cancelShift } from "src/processing/redux/actions";

export default connect(
  (state) => {
    return {
      shifts: state.processing.shifts,
      profile: state.auth.profile,
    };
  },
  {
    cancelShift,
  }
)(ShowAllShiftsScreen);
