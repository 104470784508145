import { connect } from "react-redux";
import PayForReceiptOrderScreen from "./PayForReceiptOrderScreen";
import { paymentCounterToCounteragent } from "src/counters/redux/actions";

export default connect(
  (state) => {
    return {
      profile: state.auth.profile,
      counters: state.counters.counters,
    };
  },
  { paymentCounterToCounteragent }
)(PayForReceiptOrderScreen);
