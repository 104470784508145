import React, { useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Button,
  message,
  InputNumber,
  Space,
  Typography,
  Spin,
  Checkbox,
} from "antd";
import dayjs from "dayjs";
import DatePicker from "src/common/components/DatePicker";

import BackRouteButton from "src/common/components/BackRouteButton";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import "./index.css";

const { Option } = Select;
const { Title, Text } = Typography;

export default ({
  editReceiptOrder,
  profile,
  counteragents,
  storehouse,
  receiptOrders,
}) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const [includeDelivery, setIncludeDelivery] = useState(false);
  const [selectedCounteragent, setSelectedCounteragent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [receiptOrder, setReceiptOrder] = useState(false);
  const [rawMaterials, setRawMaterials] = useState([]);

  const onFinish = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;
    values._id = receiptOrder._id;
    const { totalWeight, totalSum } = getTotalWeightAndSum(values.instances);

    values.totalWeight = totalWeight;
    values.totalSum = totalSum;

    const selectedCounteragent = counteragents.find(
      (counteragent) => counteragent._id === values.counteragent
    );
    values.counteragent = {
      firstname: selectedCounteragent.firstname,
      lastname: selectedCounteragent.lastname,
      additionalName: selectedCounteragent.additionalName,
      firm: selectedCounteragent.firm,
      id: selectedCounteragent._id,
    };

    if (values.driver) {
      const selectedDriver = counteragents.find(
        (counteragent) => counteragent._id === values.driver
      );
      values.driver = {
        firstname: selectedDriver.firstname,
        lastname: selectedDriver.lastname,
        additionalName: selectedDriver.additionalName,
        firm: selectedDriver.firm,
        id: selectedDriver._id,
      };
    }
    if (values.instances.length === 0) {
      message.error("Добавьте хотя бы одну позицию!");
      return;
    }
    values.instances = values.instances.map((instance) => {
      const selectedInstance = rawMaterials.find(
        (inst) => inst._id === instance.instance
      );
      return {
        id: selectedInstance._id,
        title: selectedInstance.title,
        weight: instance.weight,
        price: instance.price,
      };
    });

    const result = await editReceiptOrder(values);
    if (result.success) {
      form.resetFields();
      message.success("Товарная накладная успешно обновлена!");
      history.goBack();
    } else {
      message.error(
        result.message || "Не удалось обновить товарную накладную!"
      );
      setLoading(false);
    }
  };
  const handleCounteragentSelect = (selectedValue) => {
    const selected = counteragents.find((ca) => ca._id === selectedValue);
    setSelectedCounteragent(selected);
    if (selected) {
      form.setFieldsValue({
        counteragentBalance: selected.balance,
      });
    }
  };

  const handleProductFilter = (input, option) => {
    const product = rawMaterials.find((p) => p._id === option.value);

    return product.title.toLowerCase().includes(input.toLowerCase());
  };

  const updateTotalWeightAndSum = () => {
    const instancesList = form.getFieldValue("instances");
    const { totalWeight, totalSum } = getTotalWeightAndSum(instancesList);
    form.setFieldsValue({
      totalWeight,
      totalSum,
    });
  };

  const getTotalWeightAndSum = (instancesList) => {
    const { totalWeight, totalSum } = (instancesList || []).reduce(
      (acc, item) => {
        const weight = parseFloat(item.weight) || 0;
        const price = parseFloat(item.price) || 0;
        acc.totalWeight += weight;
        acc.totalSum += Number((weight * price).toFixed(2));
        return acc;
      },
      { totalWeight: 0, totalSum: 0 }
    );

    if (includeDelivery) {
      const deliveryCost = Number(form.getFieldValue("deliveryCost") || 0);
      const totalSumWithDelivery = totalSum + deliveryCost;
      return { totalWeight, totalSum: totalSumWithDelivery };
    } else {
      return { totalWeight, totalSum };
    }
  };

  const handleInstanceSelect = (value, field) => {
    const instancesList = form.getFieldValue("instances");

    const isDuplicate = instancesList.some(
      (instance) =>
        instance.instance === value &&
        instance !== form.getFieldValue(["instances", field.name])
    );

    if (isDuplicate) {
      message.error("Данный вид сырья уже добавлен в список.");
      form.setFieldsValue({
        instances: instancesList.map((instance, index) =>
          index === field.name
            ? {
                ...instance,
                instance: null,
                weight: null,
                price: null,
              }
            : instance
        ),
      });
    }
  };

  const handleCounteragentFilter = (input, option) => {
    const counteragent = counteragents.find((ca) => ca._id === option.value);

    const counteragentName = `${counteragent.lastname || ""} ${
      counteragent.firstname || ""
    } ${counteragent.additionalName || ""} ${counteragent.firm || ""}`;

    return counteragentName.toLowerCase().includes(input.toLowerCase());
  };

  const renderInstancesList = (fields, operation) => {
    return (
      <>
        <Title level={5}>Список сырья</Title>
        {fields.map((field, index) => (
          <div className="order-instance-container" key={field.key}>
            <Text style={{ width: "5%", margin: "0px" }} strong>
              {index + 1}
            </Text>
            <Space.Compact
              block
              size="small"
              key={field.key}
              direction="vertical"
            >
              <Space.Compact block size="small">
                <Form.Item
                  {...field}
                  name={[field.name, "instance"]}
                  key={[field.key, "instance"]}
                  style={{ width: "80%", margin: "0px" }}
                  rules={[
                    {
                      required: true,
                      message: "Выберите сырье",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Выберите сырье"
                    filterOption={handleProductFilter}
                    onSelect={(value) => handleInstanceSelect(value, field)}
                  >
                    {rawMaterials.map((instance) => (
                      <Option key={instance._id} value={instance._id}>
                        <h4 className="option-title-font">{instance.title}</h4>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Space.Compact>
              <Space.Compact block size="small">
                <Form.Item
                  {...field}
                  style={{ width: "40%", margin: "0px" }}
                  name={[field.name, "weight"]}
                  key={[field.key, "weight"]}
                  rules={[
                    {
                      required: true,
                      message: "Введите вес",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Вес"
                    onChange={updateTotalWeightAndSum}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: "40%", margin: "0px" }}
                  {...field}
                  name={[field.name, "price"]}
                  key={[field.key, "price"]}
                  rules={[
                    {
                      required: true,
                      message: "Введите цену",
                    },
                  ]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder="Цена"
                    onChange={updateTotalWeightAndSum}
                  />
                </Form.Item>
                <Form.Item
                  key={[field.name, "remove"]}
                  style={{ margin: "0px" }}
                  {...field}
                >
                  <MinusCircleOutlined
                    style={{ marginLeft: "4px", color: "#ff4d4f" }}
                    onClick={() => operation.remove(field.name)}
                  />
                </Form.Item>
              </Space.Compact>
            </Space.Compact>
          </div>
        ))}
        <Button
          block
          size="small"
          type="dashed"
          onClick={() => operation.add()}
          icon={<PlusOutlined />}
        >
          Добавить сырье
        </Button>
      </>
    );
  };

  useEffect(() => {
    if (receiptOrders) {
      const id = location.state.receiptOrder._id;

      for (var receiptOrder of receiptOrders) {
        if (receiptOrder._id === id) {
          setReceiptOrder(receiptOrder);
          break;
        }
      }
    }
  }, [receiptOrders]);

  useEffect(() => {
    updateTotalWeightAndSum();
  }, [includeDelivery]);
  useEffect(() => {
    if (!counteragents) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [counteragents]);

  useEffect(() => {
    if (storehouse) {
      var rawMaterials = storehouse.rawMaterialsCategories.flatMap(
        (category) => category.instances || []
      );
      setRawMaterials(rawMaterials);
    }
  }, [storehouse]);

  useEffect(() => {
    if (!receiptOrder) return;

    const dateValue = dayjs(receiptOrder.date);

    const instancesWithDetails = receiptOrder.instances.map((instance) => {
      rawMaterials.find((inst) => inst._id === instance.id);
      return {
        ...instance,
        instance: instance.id,
      };
    });
    const selected = counteragents.find(
      (ca) => ca._id === receiptOrder.counteragent.id
    );
    setSelectedCounteragent(receiptOrder.counteragent);

    form.setFieldsValue({
      date: dateValue,
      counteragent: receiptOrder.counteragent.id,
      instances: instancesWithDetails,
      totalWeight: receiptOrder.totalWeight,
      totalSum: receiptOrder.totalSum,
      comment: receiptOrder.comment,
      driver: receiptOrder.driver ? receiptOrder.driver.id : null,
      deliveryCost: receiptOrder.deliveryCost
        ? receiptOrder.deliveryCost
        : null,
      deliveryAddress: receiptOrder.deliveryAddress
        ? receiptOrder.deliveryAddress
        : null,
      counteragentBalance: selected.balance,
    });
  }, [receiptOrder]);

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <BackRouteButton />
        <h4 className="common-title--large">
          Редактировать товарную накладную
        </h4>
        <Form
          requiredMark={false}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label="Дата поставки"
            name="date"
            rules={[
              {
                required: true,
                message: "Пожалуйста, выберите дату поставки!",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Поставщик"
            name="counteragent"
            rules={[
              {
                required: true,
                message: "Пожалуйста, выберите поставщика!",
              },
            ]}
          >
            <Select
              showSearch
              size="small"
              placeholder="Выберите поставщика"
              filterOption={handleCounteragentFilter}
              onSelect={handleCounteragentSelect}
            >
              {counteragents &&
                counteragents.map((counteragent) => (
                  <Option key={counteragent._id} value={counteragent._id}>
                    <h4 className="option-title-font">
                      {`${counteragent.lastname || ""} ${
                        counteragent.firstname || ""
                      } ${counteragent.additionalName || ""} ${
                        counteragent.firm || ""
                      }`}
                    </h4>
                  </Option>
                ))}
            </Select>
          </Form.Item>
          {selectedCounteragent && (
            <Form.Item label="Баланс контрагента" name="counteragentBalance">
              <InputNumber
                size="small"
                style={{
                  width: "40%",
                  color:
                    selectedCounteragent?.balance < 0
                      ? "red"
                      : selectedCounteragent?.balance > 0
                        ? "green"
                        : "inherit",
                }}
                value={
                  selectedCounteragent
                    ? `Баланс: ${selectedCounteragent.balance} руб.`
                    : ""
                }
                formatter={(value) => {
                  if (value) {
                    return `${value} руб`;
                  }
                }}
                readOnly
              />
            </Form.Item>
          )}
          <Form.List
            initialValue={[
              {
                instance: null,
                weight: null,
                price: null,
              },
            ]}
            name="instances"
          >
            {(fields, operation) => renderInstancesList(fields, operation)}
          </Form.List>

          <Space.Compact
            block
            size="small"
            style={{
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text>Общий вес</Text>
              <Form.Item name="totalWeight">
                <InputNumber readOnly />
              </Form.Item>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text>Общая сумма</Text>
              <Form.Item name="totalSum">
                <InputNumber readOnly />
              </Form.Item>
            </div>
          </Space.Compact>
          <Form.Item label="Водитель(если есть)" name="driver">
            <Select
              showSearch
              size="small"
              filterOption={handleCounteragentFilter}
            >
              {counteragents &&
                counteragents.map((counteragent) => (
                  <Option key={counteragent._id} value={counteragent._id}>
                    <h4 className="option-title-font">
                      {`${counteragent.lastname || ""} ${
                        counteragent.firstname || ""
                      } ${counteragent.additionalName || ""} ${
                        counteragent.firm || ""
                      }`}
                    </h4>
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item label="Стоимость доставки(если есть)" name="deliveryCost">
            <InputNumber
              onChange={updateTotalWeightAndSum}
              style={{ width: "40%" }}
              size="small"
            />
          </Form.Item>
          <Form.Item name="includeDelivery">
            <Checkbox
              checked={includeDelivery}
              onChange={(e) => {
                setIncludeDelivery(e.target.checked);
              }}
              style={{ width: "40%" }}
              size="small"
            >
              Включить доставку в стоимость в заказа
            </Checkbox>
          </Form.Item>

          <Form.Item label="Комментарий" name="comment">
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Button type="primary" block size="medium" htmlType="submit">
              Обновить
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
