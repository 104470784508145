import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Select, Button, message, InputNumber, Spin } from "antd";
import BackRouteButton from "src/common/components/BackRouteButton";
import Skeleton from "src/common/components/Skeleton";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

export default ({ createStorehouseInstance, storehouse, profile }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [preparedCategories, setPreparedCategories] = useState();
  const [category, setCategory] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    values.category = {
      id: category._id,
      title: category.title,
    };
    values.type = category.type;
    values.creatorId = profile._id;
    values.sizeOfPack = parseInt(values.sizeOfPack);

    var result = await createStorehouseInstance(values);
    if (result.success) {
      form.resetFields();
      message.success("Вид (продукции/сырья) успешно добавлен!");
      history.goBack();
    } else {
      message.error(result.message || "Не удалось удалить!");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (storehouse) {
      setPreparedCategories([
        ...storehouse.productsCategories,
        ...storehouse.rawMaterialsCategories,
      ]);

      setLoading(false);
    }
  }, [storehouse]);
  useEffect(() => {
    if (category) {
      const displayValue =
        category.type === "rawMaterial" ? "Сырьё" : "Продукт";
      form.setFieldsValue({
        categoryType: displayValue,
      });
    }
  }, [category, form]);

  if (loading) return <Skeleton />;
  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <BackRouteButton />
        <h4 className="common-title--large">Добавить вид(продукции/сырья)</h4>

        <Form
          requiredMark={false}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label="Категория (продукции/сырья)"
            name="categoryId"
            rules={[
              {
                required: true,
                message: "Пожалуйста выберите категорию  (продукции/сырья)!",
              },
            ]}
          >
            <Select
              placeholder="Выберите категорию (продукции/сырья)"
              onChange={(value, option) => {
                setCategory(option);
              }}
            >
              {preparedCategories.map((category) => (
                <Option
                  key={category._id}
                  // value={category._id}
                  _id={category._id}
                  type={category.type}
                  title={category.title}
                >
                  {category.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Тип категории" name="categoryType">
            <Input readOnly />
          </Form.Item>
          <Form.Item
            label="Название вида (продукции/сырья) "
            name="title"
            rules={[
              {
                required: true,
                message: "Пожалуйста введите название вида (продукции/сырья)!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Размер упаковки(если необходим)" name="sizeOfPack">
            <InputNumber
              min={0}
              step={1}
              precision={0}
              formatter={(value) => {
                if (value) {
                  return `${value} кг`;
                }
              }}
              parser={(value) => value.replace(/\D/g, "")}
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit" loading={loading}>
              <PlusOutlined /> Добавить
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
