import createReducer from "src/utils/createReducer";
import { SET_COUNTERAGENTS } from "./types";
import { SET_COUNTERAGENT_PAYMENTS } from "./types";
import { SET_COUNTERAGENT_ORDERS } from "./types";
import { SET_COUNTERAGENT_RECEIPT_ORDERS } from "./types";
import { SET_COUNTERAGENT_SHIFTS } from "./types";
import { SET_COUNTERAGENT } from "./types";

const INITIAL_STATE = {
  counteragents: false,
  selectedCounteragent: false,
  counteragentPayments: false,
  counteragentOrders: false,
  counteragentReceiptOrders: false,
  counteragentShifts: false,
};

export default createReducer(INITIAL_STATE, {
  [SET_COUNTERAGENTS]: (state, action) => ({
    ...state,
    counteragents: action.counteragents,
  }),
  [SET_COUNTERAGENT_PAYMENTS]: (state, action) => ({
    ...state,
    counteragentPayments: action.payments,
  }),
  [SET_COUNTERAGENT_ORDERS]: (state, action) => ({
    ...state,
    counteragentOrders: action.orders,
  }),
  [SET_COUNTERAGENT_RECEIPT_ORDERS]: (state, action) => ({
    ...state,
    counteragentReceiptOrders: action.receiptOrders,
  }),
  [SET_COUNTERAGENT_SHIFTS]: (state, action) => ({
    ...state,
    counteragentShifts: action.shifts,
  }),
  [SET_COUNTERAGENT]: (state, action) => ({
    ...state,
    selectedCounteragent: action.counteragent,
  }),
});
