import { connect } from "react-redux";
import PaymentCounterToCounteragentScreen from "./PaymentCounterToCounteragentScreen";
import { paymentCounterToCounteragent } from "src/counters/redux/actions";

export default connect(
  (state) => {
    return {
      profile: state.auth.profile,
      counteragents: state.counteragents.counteragents,
      counters: state.counters.counters,
    };
  },
  { paymentCounterToCounteragent }
)(PaymentCounterToCounteragentScreen);
