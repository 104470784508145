import axios from "axios";

import { SET_COUNTERAGENTS } from "./types";
import { SET_COUNTERAGENT_PAYMENTS } from "./types";
import { SET_COUNTERAGENT_ORDERS } from "./types";
import { SET_COUNTERAGENT_RECEIPT_ORDERS } from "./types";
import { SET_COUNTERAGENT_SHIFTS } from "./types";
import { SET_COUNTERAGENT } from "./types";

const apiURL = process.env.API_URL;

export function setCounteragents(counteragents) {
  return {
    type: SET_COUNTERAGENTS,
    counteragents,
  };
}

export function getCounteragents() {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.get(`${apiURL}/counteragents`, {
        headers: {
          Authorization: state.auth.token,
        },
      });
      dispatch(setCounteragents(response.data.counteragents));
    } catch (error) {
      console.error("Error in getCounteragents: ", error.message);
    }
  };
}

export function createCounteragent(values) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await axios.post(
        `${apiURL}/counteragents/create`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getCounteragents());
      }
      return response.data;
    } catch (error) {
      console.error("Error in createCounteragent", error.message);
      return error.response.data;
    }
  };
}

export function editCounteragent(values) {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.put(`${apiURL}/counteragents/edit`, values, {
        headers: {
          Authorization: state.auth.token,
        },
      });
      if (response.data.success) {
        dispatch(getCounteragents());
      }
      return response.data;
    } catch (error) {
      console.error("Error in editCounteragent", error.message);
      return error.response.data;
    }
  };
}

export function deleteCounteragent(values) {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.delete(`${apiURL}/counteragents/delete`, {
        headers: {
          Authorization: state.auth.token,
        },
        data: values,
      });
      if (response.data.success) {
        dispatch(getCounteragents());
      }
      return response.data;
    } catch (error) {
      console.error("Error in deleteCounteragent", error.message);
      return error.response.data;
    }
  };
}

export const setCounteragentPayments = (payments) => {
  return {
    type: SET_COUNTERAGENT_PAYMENTS,
    payments,
  };
};

export function getCounteragentPayments(values) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await axios.post(
        `${apiURL}/counteragents/get/payments?loadAll=true`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );

      dispatch(setCounteragentPayments(response.data.payments));
    } catch (error) {
      console.error("Error in getCounteragentPayments", error.message);
    }
  };
}
export function getInitialCounteragentPayments(values) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await axios.post(
        `${apiURL}/counteragents/get/payments`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );

      dispatch(setCounteragentPayments(response.data.payments));
    } catch (error) {
      console.error("Error in getCounteragentPayments", error.message);
    }
  };
}

export const setCounteragentOrders = (orders) => {
  return {
    type: SET_COUNTERAGENT_ORDERS,
    orders,
  };
};

export function getInitialCounteragentOrders(values) {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.post(
        `${apiURL}/counteragents/get/orders`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );

      dispatch(setCounteragentOrders(response.data.orders));
    } catch (error) {
      console.error("Error in getCounteragentOrders", error.message);
    }
  };
}
export function getCounteragentOrders(values) {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.post(
        `${apiURL}/counteragents/get/orders?loadAll=true`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );

      dispatch(setCounteragentOrders(response.data.orders));
    } catch (error) {
      console.error("Error in getCounteragentOrders", error.message);
    }
  };
}

export const setCounteragentReceiptOrders = (receiptOrders) => {
  return {
    type: SET_COUNTERAGENT_RECEIPT_ORDERS,
    receiptOrders,
  };
};

export function getInitialCounteragentReceiptOrders(values) {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.post(
        `${apiURL}/counteragents/get/receiptOrders`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );

      dispatch(setCounteragentReceiptOrders(response.data.receiptOrders));
    } catch (error) {
      console.error("Error in getCounteragentReceiptOrders", error.message);
    }
  };
}
export function getCounteragentReceiptOrders(values) {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.post(
        `${apiURL}/counteragents/get/receiptOrders?loadAll=true`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );

      dispatch(setCounteragentReceiptOrders(response.data.receiptOrders));
    } catch (error) {
      console.error("Error in getCounteragentReceiptOrders", error.message);
    }
  };
}

export const setCounteragentShifts = (shifts) => {
  return {
    type: SET_COUNTERAGENT_SHIFTS,
    shifts,
  };
};

export function getInitialCounteragentShifts(values) {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.post(
        `${apiURL}/counteragents/get/shifts`,

        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );

      dispatch(setCounteragentShifts(response.data.shifts));
    } catch (error) {
      console.error("Error in getCounteragentShifts", error.message);
    }
  };
}
export function getCounteragentShifts(values) {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.post(
        `${apiURL}/counteragents/get/shifts?loadAll=true`,

        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );

      dispatch(setCounteragentShifts(response.data.shifts));
    } catch (error) {
      console.error("Error in getCounteragentShifts", error.message);
    }
  };
}

export function setSelectedCounteragent(counteragent) {
  return {
    type: SET_COUNTERAGENT,
    counteragent,
  };
}
