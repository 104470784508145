import { connect } from "react-redux";
import PaymentToCounterScreen from "./PaymentToCounterScreen";
import { paymentToCounter } from "src/counters/redux/actions";

export default connect(
  (state) => {
    return {
      profile: state.auth.profile,
      counters: state.counters.counters,
    };
  },
  { paymentToCounter }
)(PaymentToCounterScreen);
