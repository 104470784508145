import React, { useState, useEffect } from "react";
import { Table, Modal, Button } from "antd";
import { BarChartOutlined } from "@ant-design/icons";
import BackRouteButton from "src/common/components/BackRouteButton";

import "./index.css";

export default ({ storehouse }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const showModal = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setSelectedRecord(null);
    setIsModalVisible(false);
  };

  const prepareDataSource = () => {
    const dataSource = [];

    storehouse.rawMaterialsCategories.forEach((category) => {
      category.instances.forEach((instance) => {
        const row = {
          key: instance._id,
          title: instance.title,
          type: instance.type,
          sizeOfPack: instance.sizeOfPack,
          category: instance.category.title,
          weight: instance.weight,
          avaragePrice: instance.avaragePrice,
        };
        dataSource.push(row);
      });
    });

    return dataSource;
  };

  const columns = [
    {
      title: <span className="column-title-font">Наименование</span>,
      dataIndex: "title",
      key: "title",

      render: (text) => <span className="column-content-font">{text}</span>,
    },
    {
      title: <span className="column-title-font">Доступно</span>,
      dataIndex: "weight",
      key: "weight",

      render: (weight) => (
        <span className="column-content-font">{weight.totalAvailable}кг</span>
      ),
    },

    {
      title: <span className="column-title-font">Подробнее</span>,
      dataIndex: "moreInfo",
      key: "moreInfo",
      render: (_, record) => (
        <>
          <Button
            type="link"
            onClick={() => showModal(record)}
            className="button--balance-modal"
          >
            <BarChartOutlined />
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (!data) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [data]);
  useEffect(() => {
    if (storehouse) {
      setData(prepareDataSource());
    }
  }, [storehouse]);

  return (
    <div className="common-container">
      <BackRouteButton />
      <h4 className="common-title--large">Баланс сырья</h4>
      <Table
        loading={loading}
        pagination={false}
        dataSource={data}
        columns={columns}
      />
      {selectedRecord && (
        <Modal
          title={<h3 className="common-modal-title">{selectedRecord.title}</h3>}
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <div className="custom-modal-content">
            <span className="column-content-font">
              Всего доступно для переработки:{" "}
              {selectedRecord.weight.totalAvailable}кг
            </span>
            <span className="column-content-font">
              Средняя цена остатка в складе:{" "}
              {selectedRecord.avaragePrice
                ? `${selectedRecord.avaragePrice.toFixed(2)} Руб. за 1 кг`
                : ""}
            </span>
            <h3 className="common-modal-title">Статистика</h3>
            <span className="column-content-font">
              Всего было переработано: {selectedRecord.weight.totalProcessed}кг
            </span>
            <span className="column-content-font">
              Всего было куплено: {selectedRecord.weight.totalBought}кг
            </span>
            <span className="column-content-font">
              Всего было списано: {selectedRecord.weight.totalWriteOffs}кг
            </span>
          </div>
        </Modal>
      )}
    </div>
  );
};
