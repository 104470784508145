import { connect } from "react-redux";
import InitialRawMaterialsScreen from "./InitialRawMaterialsScreen";
import { initialRawMaterials } from "src/storehouse/redux/actions";

export default connect(
  (state) => {
    return {
      profile: state.auth.profile,
      storehouse: state.storehouse.storehouse,
    };
  },
  { initialRawMaterials }
)(InitialRawMaterialsScreen);
