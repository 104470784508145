import createReducer from "src/utils/createReducer";
import { SET_COUNTERS } from "./types";

const INITIAL_STATE = {
  counters: false,
};

export default createReducer(INITIAL_STATE, {
  [SET_COUNTERS]: (state, action) => ({
    ...state,
    counters: action.counters,
  }),
});
