import createReducer from "src/utils/createReducer";
import { SET_TOKEN, SET_PROFILE, SET_ACTIONS } from "./types";

const INITIAL_STATE = {
  token: false,
  profile: false,
  actions: false,
};

export default createReducer(INITIAL_STATE, {
  [SET_TOKEN]: (state, action) => ({
    ...state,
    token: action.token,
  }),
  [SET_PROFILE]: (state, action) => ({
    ...state,
    profile: action.profile,
  }),
  [SET_ACTIONS]: (state, action) => ({
    ...state,
    actions: action.actions,
  }),
});
