import React from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import locale from "antd/es/date-picker/locale/ru_RU";

dayjs.extend(utc);
dayjs.extend(timezone);

const CustomDatePicker = (props) => {
  const { onChange, value, ...restProps } = props;

  const handleChange = (date, dateString) => {
    if (date) {
      const dateInMoscow = dayjs.tz(
        dateString,
        "DD.MM.YYYY",
        "Europe/Moscow",
        true
      );

      const moscowCurrentTime = dayjs().tz("Europe/Moscow");
      const adjustedDate = dateInMoscow
        .hour(moscowCurrentTime.hour())
        .minute(moscowCurrentTime.minute())
        .second(moscowCurrentTime.second());

      onChange && onChange(adjustedDate, adjustedDate.format("DD.MM.YYYY"));
    } else {
      onChange && onChange(null, "");
    }
  };

  const adjustedValue = value
    ? dayjs.tz(value.format("DD.MM.YYYY"), "DD.MM.YYYY", "Europe/Moscow", true)
    : value;

  return (
    <DatePicker
      locale={locale}
      {...restProps}
      value={adjustedValue}
      onChange={handleChange}
      size="small"
      format="DD.MM.YYYY"
      style={{
        width: "50%",
      }}
    />
  );
};

export default CustomDatePicker;
