import React from "react";
import RoutePage from "src/common/components/RoutePage/RoutePage";
import { SettingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

export default () => {
  const profile = useSelector((state) => state.auth.profile);

  const storehouseLinks = {
    prefix: "/storehouse",
    links: [
      {
        link: "/rawmaterials",
        title: "Баланс сырья",
        rang: "user",
      },
      {
        link: "/products",
        title: "Баланс продукции",
        rang: "user",
      },
      {
        link: "/receiptorder/create",
        title: "Добавить сырье/создать товарную накладную",
        rang: "user",
      },
      {
        link: "/receiptorders",
        title: "Все товарные накладные",
        rang: "user",
      },
      {
        link: "/report",
        title: "Создать отчёт",
        rang: "user",
      },
      {
        link: "/settings",
        title: "Настройки склада",
        rang: "user",
        icon: <SettingOutlined />,
        sublinks: [
          {
            link: "/category/create",
            title: "Добавить категорию склада",
            rang: "user",
          },
          {
            link: "/categories",
            title: "Все категории склада",
            rang: "user",
          },

          {
            link: "/instance/create",
            title: "Добавить вид (сырья/продукции)",
            rang: "user",
          },
          {
            link: "/instances",
            title: "Все виды (сырья/продукции)",
            rang: "user",
          },
          {
            link: "/initial/raw-materials",
            title: "Ввести начальные остатки сырья",
            rang: "user",
          },
          {
            link: "/initial/products",
            title: "Ввести начальные остатки продукции",
            rang: "user",
          },
          {
            link: "/din-mel/prices",
            title: "Настроить цены на din-mel.ru",
            rang: "user",
          },
        ],
      },
    ],
  };

  return <RoutePage values={storehouseLinks} profile={profile} />;
};
