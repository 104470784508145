import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

import { Table, Modal, Button, Radio, message, Space, Spin, Input } from "antd";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  RightOutlined,
  LeftOutlined,
} from "@ant-design/icons";

import "./index.css";

export default ({
  counteragents,
  deleteCounteragent,
  profile,
  getCounteragentPayments,
  getCounteragentOrders,
  getCounteragentReceiptOrders,
  getCounteragentShifts,
  setSelectedCounteragent,
  selectedCounteragent,
  setCounteragentOrders,
  setCounteragentReceiptOrders,
  setCounteragentShifts,
  setCounteragentPayments,
  getInitialCounteragentPayments,
  getInitialCounteragentOrders,
  getInitialCounteragentReceiptOrders,
  getInitialCounteragentShifts,
}) => {
  let history = useHistory();
  let { filter: routeFilter } = useParams();
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filter, setFilter] = useState(routeFilter);
  const [searchValue, setSearchValue] = useState("");
  const [dataSource, setDataSource] = useState([]);

  function comeback() {
    history.push("/counteragents");
  }

  const validFilters = [
    "all",
    "employees",
    "buyers",
    "suppliers",
    "positive",
    "negative",
  ];

  if (!validFilters.includes(routeFilter)) {
    routeFilter = "all";
  }

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setFilter(value);
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleEditCounteragent = async (counteragent) => {
    history.push({
      pathname: `/counteragents/edit`,
      state: {
        counteragent,
        listViewState: { filter, isModalVisible, searchValue },
        from: history.location.pathname,
      },
    });
  };

  const handleShowCounteragentPayments = async (counteragent) => {
    getInitialCounteragentPayments(counteragent);
    getCounteragentPayments(counteragent);
    history.push({
      pathname: `/counteragents/get/payments`,
      state: {
        listViewState: { filter, isModalVisible, searchValue },
        from: history.location.pathname,
      },
    });
  };

  const handleShowCounteragentOrders = (counteragent) => {
    getInitialCounteragentOrders(counteragent);
    getCounteragentOrders(counteragent);
    history.push({
      pathname: `/counteragents/get/orders`,
      state: {
        listViewState: { filter, isModalVisible, searchValue },
        from: history.location.pathname,
      },
    });
  };

  const handleShowCounteragentReceiptOrders = (counteragent) => {
    getInitialCounteragentReceiptOrders(counteragent);
    getCounteragentReceiptOrders(counteragent);
    history.push({
      pathname: `/counteragents/get/receiptorders`,
      state: {
        listViewState: { filter, isModalVisible, searchValue },
        from: history.location.pathname,
      },
    });
  };

  const handleShowCounteragentShifts = (counteragent) => {
    getInitialCounteragentShifts(counteragent);
    getCounteragentShifts(counteragent);
    history.push({
      pathname: `/counteragents/get/shifts`,
      state: {
        listViewState: { filter, isModalVisible, searchValue },
        from: history.location.pathname,
      },
    });
  };

  const handleDeleteCounteragent = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;
    var result = await deleteCounteragent(values);

    if (result.success) {
      message.success("Контрагент успешно удалён!");
      setLoading(false);
    } else {
      message.error(result.message || "Не удалось удалить!");
      setLoading(false);
    }
  };

  const showConfirm = (category) => {
    Modal.confirm({
      title: "Вы уверены, что хотите удалить этого контрагента?",
      icon: <ExclamationCircleOutlined />,
      content: "Это действие не может быть отменено.",
      okText: "Да, удалить",
      okType: "danger",
      cancelText: "Отмена",
      onOk() {
        handleDeleteCounteragent(category);
      },
      onCancel() {
        console.log("Cancelled");
      },
    });
  };

  const showModal = (counteragent) => {
    setSelectedCounteragent(counteragent);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setCounteragentOrders(false);
    setCounteragentReceiptOrders(false);
    setCounteragentShifts(false);
    setCounteragentPayments(false);
    setIsModalVisible(false);
  };

  const filterCounteragents = (filter, counteragents, searchValue) => {
    let filteredData = counteragents;

    switch (filter) {
      case "buyers":
        filteredData = filteredData.filter((c) => c.type === "покупатель");
        break;
      case "suppliers":
        filteredData = filteredData.filter((c) => c.type === "поставщик");
        break;
      case "employees":
        filteredData = filteredData.filter(
          (c) => c.type === "сотрудник" || c.type === "работник цеха"
        );
        break;
      case "workers":
        filteredData = filteredData.filter((c) => c.type === "работник цеха");
        break;
      case "positive":
        filteredData = filteredData.filter((c) => c.balance > 0);
        break;
      case "negative":
        filteredData = filteredData.filter((c) => c.balance < 0);
        break;
      default:
        break;
    }

    if (searchValue) {
      filteredData = filteredData.filter(
        (c) =>
          c.firstname?.toLowerCase().includes(searchValue.toLowerCase()) ||
          c.lastname?.toLowerCase().includes(searchValue.toLowerCase()) ||
          (c.additionalName &&
            c.additionalName
              .toLowerCase()
              .includes(searchValue.toLowerCase())) ||
          (c.firm && c.firm.toLowerCase().includes(searchValue.toLowerCase()))
      );
    }

    return filteredData;
  };

  const prepareDataSource = (filteredData) => {
    return filteredData.map((counteragent) => ({
      key: counteragent._id,
      nameInfo: `${counteragent.firm || ""} ${counteragent.lastname || ""} ${
        counteragent.firstname || ""
      } ${counteragent.additionalName || ""}`,
      balance: counteragent.balance,
      ...counteragent,
    }));
  };

  const columns = [
    {
      title: <span className="column-title-font">Инфо об имени</span>,
      dataIndex: "nameInfo",
      key: "nameInfo",
      render: (text) => <span className="column-content-font">{text}</span>,
    },
    {
      title: <span className="column-title-font">Баланс</span>,
      dataIndex: "balance",
      key: "balance",
      render: (text) => (
        <span className="column-content-font">{text} руб.</span>
      ),
    },
    {
      title: <span className="column-title-font">Действия</span>,
      dataIndex: "details",
      key: "details",
      render: (_, record) => (
        <Space>
          <Button
            className="common-table-button"
            type="link"
            size="small"
            icon={<EyeOutlined />}
            onClick={() => showModal(record)}
          />
          <Button
            className="common-table-button"
            type="link"
            size="small"
            icon={<EditOutlined />}
            onClick={() => handleEditCounteragent(record)}
          />
          <Button
            className="common-table-button"
            type="link"
            size="small"
            icon={<DeleteOutlined />}
            onClick={() => showConfirm(record)}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (!counteragents || counteragents.length === 0) {
      setLoading(true);
      return;
    }

    const filteredCounteragents = filterCounteragents(
      filter,
      counteragents,
      searchValue
    );

    const dataSource = prepareDataSource(filteredCounteragents);
    setDataSource(dataSource);

    setLoading(false);
  }, [filter, counteragents, searchValue]);
  useEffect(() => {
    if (history.location.state?.listViewState) {
      const { filter, isModalVisible, searchValue } =
        history.location.state.listViewState;
      setFilter(filter);
      setIsModalVisible(isModalVisible);
      setSearchValue(searchValue);
    }
  }, [history.location.state]);

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <Button type="ghost" onClick={comeback} className="back-route-button">
          <LeftOutlined />
          <h4 className="back-route-button--title">назад </h4>
        </Button>

        <br />

        <h4 className="common-title--large">Все контрагенты</h4>
        <Input.Search
          value={searchValue}
          placeholder="Поиск по имени или фирме"
          onChange={handleSearchChange}
          style={{ marginBottom: 16 }}
          size="small"
        />
        <Radio.Group
          onChange={handleFilterChange}
          value={filter}
          style={{ marginBottom: 16 }}
          size="small"
        >
          <Radio.Button value="all">Все</Radio.Button>
          <Radio.Button value="buyers">Покупатели</Radio.Button>
          <Radio.Button value="suppliers">Поставщики</Radio.Button>
          <Radio.Button value="employees">Все сотрудники</Radio.Button>
          <Radio.Button value="workers">Работники цеха</Radio.Button>
          <Radio.Button value="positive">Баланс выше 0</Radio.Button>
          <Radio.Button value="negative">Баланс ниже 0</Radio.Button>
        </Radio.Group>
        <Table
          pagination={{ pageSize: 20 }}
          dataSource={dataSource}
          columns={columns}
        />
        <Modal
          title="Информация о контрагенте"
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          {selectedCounteragent && (
            <div className="common-modal__info-block">
              {selectedCounteragent.firm && (
                <div className="counteragent-info-container">
                  <span className="column-title-font">Фирма:</span>
                  <span className="column-content-font">
                    {selectedCounteragent.firm}
                  </span>
                </div>
              )}
              {selectedCounteragent.firstname && (
                <div className="counteragent-info-container">
                  <span className="column-title-font">Имя:</span>
                  <span className="column-content-font">
                    {selectedCounteragent.firstname}
                  </span>
                </div>
              )}
              {selectedCounteragent.lastname && (
                <div className="counteragent-info-container">
                  <span className="column-title-font">Фамилия:</span>
                  <span className="column-content-font">
                    {selectedCounteragent.lastname}
                  </span>
                </div>
              )}
              {selectedCounteragent.additionalName && (
                <div className="counteragent-info-container">
                  <span className="column-title-font">Отчество:</span>
                  <span className="column-content-font">
                    {selectedCounteragent.additionalName}
                  </span>
                </div>
              )}

              {selectedCounteragent.phone && (
                <div className="counteragent-info-container">
                  <span className="column-title-font">Телефон:</span>
                  <span className="column-content-font">
                    {selectedCounteragent.phone}
                  </span>
                </div>
              )}

              {selectedCounteragent.email && (
                <div className="counteragent-info-container">
                  <span className="column-title-font">Электронная почта:</span>
                  <span className="column-content-font">
                    {selectedCounteragent.email}
                  </span>
                </div>
              )}
              {selectedCounteragent.address && (
                <div className="counteragent-info-container">
                  <span className="column-title-font">Адрес:</span>
                  <span className="column-content-font">
                    {selectedCounteragent.address}
                  </span>
                </div>
              )}
              {selectedCounteragent.comment && (
                <div className="counteragent-info-container">
                  <span className="column-title-font">Комментарий:</span>
                  <span className="column-content-font">
                    {selectedCounteragent.comment}
                  </span>
                </div>
              )}

              <div className="counteragent-info-container">
                <span className="column-title-font">Тип:</span>
                <span className="column-content-font">
                  {selectedCounteragent.type}
                </span>
              </div>
              {selectedCounteragent.profession && (
                <div className="counteragent-info-container">
                  <span className="column-title-font">Профессия:</span>
                  <span className="column-content-font">
                    {selectedCounteragent.profession}
                  </span>
                </div>
              )}
              <div className="counteragent-info-container">
                <span className="column-title-font">Баланс:</span>
                <span className="column-content-font">
                  {selectedCounteragent.balance} Руб.
                </span>
              </div>

              <Button
                type="text"
                size="large"
                onClick={() => {
                  handleShowCounteragentPayments(selectedCounteragent);
                }}
              >
                <div className="link__title__container--counteragent-modal">
                  <p className="link__title--counteragent-modal">
                    Все платежи контрагента
                  </p>
                  <RightOutlined
                    style={{
                      fontSize: "10px",
                    }}
                  />
                </div>
              </Button>
              <Button
                type="text"
                size="large"
                onClick={() => {
                  handleShowCounteragentOrders(selectedCounteragent);
                }}
              >
                <div className="link__title__container--counteragent-modal">
                  <p className="link__title--counteragent-modal">
                    Все заказы контрагента
                  </p>
                  <RightOutlined
                    style={{
                      fontSize: "10px",
                    }}
                  />
                </div>
              </Button>

              <Button
                type="text"
                size="large"
                onClick={() => {
                  handleShowCounteragentReceiptOrders(selectedCounteragent);
                }}
              >
                <div className="link__title__container--counteragent-modal">
                  <p className="link__title--counteragent-modal">
                    Все товарные накладные контрагента
                  </p>
                  <RightOutlined
                    style={{
                      fontSize: "10px",
                    }}
                  />
                </div>
              </Button>

              <Button
                type="text"
                size="large"
                onClick={() => {
                  handleShowCounteragentShifts(selectedCounteragent);
                }}
              >
                <div className="link__title__container--counteragent-modal">
                  <p className="link__title--counteragent-modal">
                    Все смены контрагента
                  </p>
                  <RightOutlined
                    style={{
                      fontSize: "10px",
                    }}
                  />
                </div>
              </Button>
            </div>
          )}
        </Modal>
      </Spin>
    </div>
  );
};
