import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Button,
  message,
  InputNumber,
  Space,
  Typography,
  Spin,
} from "antd";

import DatePicker from "src/common/components/DatePicker";
import BackRouteButton from "src/common/components/BackRouteButton";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import "./index.css";

const { Option } = Select;
const { Title, Text } = Typography;

export default ({ initialProducts, profile, storehouse }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [instances, setInstances] = useState(form.getFieldValue("instances"));
  const [products, setProducts] = useState([]);

  const onFinish = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;
    const { totalWeight, totalSum } = getTotalWeightAndSum(values.instances);
    values.totalWeight = totalWeight;
    values.totalSum = totalSum;
    values.period = "day";

    if (values.instances.length === 0) {
      message.error("Добавьте хотя бы одну позицию!");
      return;
    }

    values.instances = values.instances.map((instance) => {
      const selectedInstance = products.find(
        (inst) => inst._id === instance.id
      );
      return {
        id: selectedInstance._id,
        title: selectedInstance.title,
        weight: instance.weight,
      };
    });

    const result = await initialProducts(values);
    if (result.success) {
      form.resetFields();
      message.success("Ввод начальных остатков произведен успешно!");
      history.goBack();
    } else {
      message.error(result.message || "Не удалось ввести остатки!");
      setLoading(false);
    }
  };
  const updateTotalWeightAndSum = () => {
    const instancesList = form.getFieldValue("instances");
    const { totalWeight, totalSum } = getTotalWeightAndSum(instancesList);
    form.setFieldsValue({
      totalWeight,
      totalSum,
    });
  };

  const getTotalWeightAndSum = (instancesList) => {
    return (instancesList || []).reduce(
      (acc, item) => {
        const weight = parseFloat(item.weight) || 0;
        const price = parseFloat(item.price) || 0;
        acc.totalWeight += weight;
        acc.totalSum += Number((weight * price).toFixed(2));
        return acc;
      },
      { totalWeight: 0, totalSum: 0 }
    );
  };

  const handleProductFilter = (input, option) => {
    const product = products.find((p) => p._id === option.value);

    return product.title.toLowerCase().includes(input.toLowerCase());
  };

  const handleInstanceSelect = (value, field) => {
    const instancesList = form.getFieldValue("instances");

    const isDuplicate = instancesList.some(
      (instance) =>
        instance.id === value &&
        instance !== form.getFieldValue(["instances", field.name])
    );

    if (isDuplicate) {
      message.error("Данный вид продукции уже добавлен в список.");
      form.setFieldsValue({
        instances: instancesList.map((instance, index) =>
          index === field.name
            ? {
                ...instance,
                id: null,
                weight: null,
                sizeOfPack: null,
              }
            : instance
        ),
      });
    }
  };

  const renderInstancesList = (fields, operation) => {
    return (
      <>
        <Title level={5}>Начальные остатки продукции:</Title>
        {fields.map((field, index) => (
          <Space.Compact block size="small" key={`${field.name}-${index}`}>
            <Form.Item
              {...field}
              name={[field.name, "id"]}
              key={[field.key, "id"]}
              style={{ minWidth: "50%", margin: "0px" }}
              rules={[
                {
                  required: true,
                  message: "Выберите продукт",
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Выберите продукт"
                filterOption={(input, option) =>
                  handleProductFilter(input, option, products)
                }
                onSelect={(value) => handleInstanceSelect(value, field)}
              >
                {products.map((instance) => (
                  <Option key={instance._id} value={instance._id}>
                    <h4 className="option-title-font">{instance.title}</h4>
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              {...field}
              style={{ width: "30%", margin: "0px" }}
              name={[field.name, "weight"]}
              key={[field.key, "weight"]}
              rules={[
                {
                  required: true,
                  message: "Введите вес",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Вес"
                onChange={updateTotalWeightAndSum}
              />
            </Form.Item>
            <Form.Item style={{ margin: "0px" }} {...field}>
              <MinusCircleOutlined
                style={{ marginLeft: "4px", color: "#ff4d4f" }}
                onClick={() => operation.remove(field.name)}
              />
            </Form.Item>
          </Space.Compact>
        ))}
        <Button
          block
          size="small"
          type="dashed"
          onClick={() => operation.add()}
          icon={<PlusOutlined />}
        >
          Добавить продукт
        </Button>
      </>
    );
  };

  useEffect(() => {
    if (storehouse) {
      var products = storehouse.productsCategories.flatMap(
        (category) => category.instances || []
      );

      for (var [index, product] of products.entries()) {
        product.title =
          product.title.replace(
            /пшеничная|марка|пшеничные|Хлебопекарная/g,
            ""
          ) +
          " " +
          product.sizeOfPack +
          "кг";

        products[index] = product;
      }
      setProducts(products);
    }
  }, [storehouse]);

  useEffect(() => {
    setInstances(form.getFieldValue("instances"));
  }, [form]);

  useEffect(() => {
    const calculations = getTotalWeightAndSum(instances);
    form.setFieldsValue({
      totalWeight: calculations.totalWeight,
      totalSum: calculations.totalSum,
    });
  }, [instances]);

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <BackRouteButton />
        <h4 className="common-title--large">
           Введите начальные остатки продукции
        </h4>
        <Form
          requiredMark={false}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label="Дата:"
            name="date"
            rules={[
              {
                required: true,
                message: "Пожалуйста, выберите дату!",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>

          <Form.List
            initialValue={[
              {
                id: null,
                weight: null,
                price: null,
              },
            ]}
            name="instances"
          >
            {(fields, operation) => renderInstancesList(fields, operation)}
          </Form.List>

          <Space.Compact
            block
            size="small"
            style={{
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text>Общий вес</Text>
              <Form.Item name="totalWeight">
                <InputNumber readOnly />
              </Form.Item>
            </div>
          </Space.Compact>

          <Form.Item label="Комментарий" name="comment">
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Button type="primary" block size="medium" htmlType="submit">
              Создать
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
