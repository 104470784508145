import { connect } from "react-redux";
import InitialProductsScreen from "./InitialProductsScreen";
import { initialProducts } from "src/storehouse/redux/actions";

export default connect(
  (state) => {
    return {
      profile: state.auth.profile,
      storehouse: state.storehouse.storehouse,
    };
  },
  { initialProducts }
)(InitialProductsScreen);
