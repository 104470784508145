import { connect } from "react-redux";

import LoginScreen from "./LoginScreen";

import { login } from "src/auth/redux/actions";

export default connect(
  (state) => {
    return {};
  },
  {
    login,
  }
)(LoginScreen);
