import { connect } from "react-redux";
import PayForOrderScreen from "./PayForOrderScreen";
import { paymentCounteragentToCounter } from "src/counters/redux/actions";

export default connect(
  (state) => {
    return {
      profile: state.auth.profile,
      counters: state.counters.counters,
    };
  },
  { paymentCounteragentToCounter }
)(PayForOrderScreen);
