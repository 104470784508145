import { connect } from "react-redux";
import EditRepairScreen from "./EditRepairScreen";
import { editRepair } from "src/processing/redux/actions";

export default connect(
  (state) => {
    return {
      profile: state.auth.profile,
      repairs: state.processing.repairs,
    };
  },
  { editRepair }
)(EditRepairScreen);
