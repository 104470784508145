import axios from "axios";

import { SET_COUNTERS } from "./types";

const apiURL = process.env.API_URL;

function setCounters(counters) {
  return {
    type: SET_COUNTERS,
    counters,
  };
}

export function getCounters() {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.get(`${apiURL}/counters?loadAll=true`, {
        headers: {
          Authorization: state.auth.token,
        },
      });
      dispatch(setCounters(response.data.counters));
    } catch (error) {
      console.error("Error in getCounters: ", error.message);
    }
  };
}
export function getInitialCounters() {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.get(`${apiURL}/counters`, {
        headers: {
          Authorization: state.auth.token,
        },
      });
      dispatch(setCounters(response.data.counters));
    } catch (error) {
      console.error("Error in getCounters: ", error.message);
    }
  };
}

export function paymentCounterToCounteragent(values) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await axios.post(
        `${apiURL}/counters/payment/counter-to-counteragent`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getCounters());
      }
      return response.data;
    } catch (error) {
      console.error("Error in paymentCounterToCounteragent", error.message);
      return error.response.data;
    }
  };
}

export function paymentCounteragentToCounter(values) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await axios.post(
        `${apiURL}/counters/payment/counteragent-to-counter`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getCounters());
      }
      return response.data;
    } catch (error) {
      console.error("Error in paymentCounteragentToCounter", error.message);
      return error.response.data;
    }
  };
}

export function paymentCounterToCounter(values) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await axios.post(
        `${apiURL}/counters/payment/counter-to-counter`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getCounters());
      }
      return response.data;
    } catch (error) {
      console.error("Error in paymentCounterToCounter", error.message);
      return error.response.data;
    }
  };
}

export function paymentFromCounter(values) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await axios.post(
        `${apiURL}/counters/payment/from-counter`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getCounters());
      }
      return response.data;
    } catch (error) {
      console.error("Error in paymentFromCounter", error.message);
      return error.response.data;
    }
  };
}

export function paymentToCounter(values) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await axios.post(
        `${apiURL}/counters/payment/to-counter`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getCounters());
      }
      return response.data;
    } catch (error) {
      console.error("Error in paymentToCounter", error.message);
      return error.response.data;
    }
  };
}
export function addOnBalanceOfCounteragent(values) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await axios.post(
        `${apiURL}/counters/payment/add-on-balance-of-counteragent`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getCounters());
      }
      return response.data;
    } catch (error) {
      console.error("Error in addOnBalanceOfCounteragent", error.message);
      return error.response.data;
    }
  };
}

export function cancelPayment(values) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await axios.put(
        `${apiURL}/counters/payment/cancel`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getCounters());
      }
      return response.data;
    } catch (error) {
      console.error("Error in cancelPayment", error.message);
      return error.response.data;
    }
  };
}

export function verifyPayment(values) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      // {
      const response = await axios.put(
        `${apiURL}/counters/payment/verify`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getCounters());
      }
      return response.data;
    } catch (error) {
      console.error("Error in verifyPayment", error.message);
      return error.response.data;
    }
  };
}
