import axios from "axios";

import { SET_SHIFTS, SET_REPAIRS, SET_ANALYSES } from "./types";

const apiURL = process.env.API_URL;

export const setShifts = (shifts) => {
  return {
    type: SET_SHIFTS,
    shifts,
  };
};

export const getShifts = () => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.get(`${apiURL}/processing`, {
        headers: {
          Authorization: state.auth.token,
        },
      });
      dispatch(setShifts(response.data.shifts));
    } catch (error) {
      console.error("Error in getShifts: ", error.message);
    }
  };
};

export const createShift = (values) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await axios.post(
        `${apiURL}/processing/shift/create`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getShifts());
      }
      return response.data;
    } catch (error) {
      console.error("Error in createShift", error.message);
      return error.response.data;
    }
  };
};

export const editShift = (values) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.put(
        `${apiURL}/processing/shift/edit`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getShifts());
      }
      return response.data;
    } catch (error) {
      console.error("Error in editShift", error.message);
      return error.response.data;
    }
  };
};

export const cancelShift = (values) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.put(
        `${apiURL}/processing/shift/cancel`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getShifts());
      }
      return response.data;
    } catch (error) {
      console.error("Error in cancelShift", error.message);
      return error.response.data;
    }
  };
};

export const setRepairs = (repairs) => {
  return {
    type: SET_REPAIRS,
    repairs,
  };
};

export const getRepairs = () => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.get(`${apiURL}/processing/repairs`, {
        headers: {
          Authorization: state.auth.token,
        },
      });
      dispatch(setRepairs(response.data.repairs));
    } catch (error) {
      console.error("Error in getRepairs: ", error.message);
    }
  };
};

export const createRepair = (values) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await axios.post(
        `${apiURL}/processing/repair/create`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getRepairs());
      }
      return response.data;
    } catch (error) {
      console.error("Error in createRepair", error.message);
      return error.response.data;
    }
  };
};

export const editRepair = (values) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.put(
        `${apiURL}/processing/repair/edit`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getRepairs());
      }
      return response.data;
    } catch (error) {
      console.error("Error in editRepair", error.message);
      return error.response.data;
    }
  };
};

export const cancelRepair = (values) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.put(
        `${apiURL}/processing/repair/cancel`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getRepairs());
      }
      return response.data;
    } catch (error) {
      console.error("Error in cancelRepair", error.message);
      return error.response.data;
    }
  };
};

export const setAnalyses = (analyses) => {
  return {
    type: SET_ANALYSES,
    analyses,
  };
};

export const getAnalyses = () => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.get(`${apiURL}/processing/analyses`, {
        headers: {
          Authorization: state.auth.token,
        },
      });
      dispatch(setAnalyses(response.data.analyses));
    } catch (error) {
      console.error("Error in getAnalyses: ", error.message);
    }
  };
};

export const editAnalysis = (values) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.put(
        `${apiURL}/processing/analysis/edit`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getAnalyses());
      }
      return response.data;
    } catch (error) {
      console.error("Error in editAnalysis", error.message);
      return error.response.data;
    }
  };
};

export const createAnalysis = (values) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await axios.post(
        `${apiURL}/processing/analysis/create`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getAnalyses());
      }
      return response.data;
    } catch (error) {
      console.error("Error in createAnalysis", error.message);
      return error.response.data;
    }
  };
};

export const cancelAnalysis = (values) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.put(
        `${apiURL}/processing/analysis/cancel`,
        values,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      if (response.data.success) {
        dispatch(getAnalyses());
      }
      return response.data;
    } catch (error) {
      console.error("Error in cancelAnalysis", error.message);
      return error.response.data;
    }
  };
};
