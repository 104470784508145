import { connect } from "react-redux";
import ShowAllAnalysesScreen from "./ShowAllAnalysesScreen";
import { cancelAnalysis, editAnalysis } from "src/processing/redux/actions";

export default connect(
  (state) => {
    return {
      analyses: state.processing.analyses,
      profile: state.auth.profile,
    };
  },
  {
    cancelAnalysis,
    editAnalysis,
  }
)(ShowAllAnalysesScreen);
