import createReducer from "src/utils/createReducer";
import { SET_SHIFTS, SET_REPAIRS, SET_ANALYSES } from "./types";

const INITIAL_STATE = {
  shifts: false,
  repairs: false,
  analyses: false,
};
export default createReducer(INITIAL_STATE, {
  [SET_SHIFTS]: (state, action) => {
    return {
      ...state,
      shifts: action.shifts,
    };
  },
  [SET_REPAIRS]: (state, action) => {
    return {
      ...state,
      repairs: action.repairs,
    };
  },
  [SET_ANALYSES]: (state, action) => {
    return {
      ...state,
      analyses: action.analyses,
    };
  },
});
