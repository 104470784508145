import axios from "axios";
import { SET_ORDERS } from "./types";

const apiURL = process.env.API_URL;

export const setOrders = (orders) => {
  return {
    type: SET_ORDERS,
    orders,
  };
};

export const getInitialOrders = () => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.get(`${apiURL}/orders?limit=20`, {
        headers: {
          Authorization: state.auth.token,
        },
      });
      dispatch(setOrders(response.data.orders));
    } catch (error) {
      console.error("Error in getInitialOrders: ", error.message);
    }
  };
};

export const getOrders = () => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.get(`${apiURL}/orders?loadAll=true`, {
        headers: {
          Authorization: state.auth.token,
        },
      });
      dispatch(setOrders(response.data.orders));
    } catch (error) {
      console.error("Error in getOrders: ", error.message);
    }
  };
};

export const createOrder = (values) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const response = await axios.post(`${apiURL}/orders/create`, values, {
        headers: {
          Authorization: state.auth.token,
        },
      });
      if (response.data.success) {
        dispatch(getOrders());
      }
      return response.data;
    } catch (error) {
      console.error("Error in createOrder", error.message);
      return error.response.data;
    }
  };
};

export const editOrder = (values) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.put(`${apiURL}/orders/edit`, values, {
        headers: {
          Authorization: state.auth.token,
        },
      });
      if (response.data.success) {
        dispatch(getOrders());
      }
      return response.data;
    } catch (error) {
      console.error("Error in editOrder", error.message);
      return error.response.data;
    }
  };
};

export const cancelOrder = (values) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.put(`${apiURL}/orders/cancel`, values, {
        headers: {
          Authorization: state.auth.token,
        },
      });
      if (response.data.success) {
        dispatch(getOrders());
      }
      return response.data;
    } catch (error) {
      console.error("Error in cancelOrder", error.message);
      return error.response.data;
    }
  };
};

export const shipOrder = (values) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.put(`${apiURL}/orders/ship`, values, {
        headers: {
          Authorization: state.auth.token,
        },
      });
      if (response.data.success) {
        dispatch(getOrders());
      }
      return response.data;
    } catch (error) {
      console.error("Error in shipOrder", error.message);
      return error.response.data;
    }
  };
};

export const cancelShipOrder = (values) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.put(`${apiURL}/orders/cancel/ship`, values, {
        headers: {
          Authorization: state.auth.token,
        },
      });
      if (response.data.success) {
        dispatch(getOrders());
      }
      return response.data;
    } catch (error) {
      console.error("Error in cancelShipOrder", error.message);
      return error.response.data;
    }
  };
};

export const returnOrder = (values) => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      const response = await axios.put(`${apiURL}/orders/return`, values, {
        headers: {
          Authorization: state.auth.token,
        },
      });
      if (response.data.success) {
        dispatch(getOrders());
      }
      return response.data;
    } catch (error) {
      console.error("Error in returnOrder", error.message);
      return error.response.data;
    }
  };
};
