var groupsOfLinks = [
  {
    prefix: "/processing",
    links: [
      {
        link: "/shift/create",
        title: "Записать смену",
        rang: "user",
      },
      { link: "/shifts", title: "Все смены", rang: "user" },
      {
        link: "/repair",
        title: "Журнал ремонтов",
        rang: "user",
        sublinks: [
          {
            link: "/create",
            title: "Записать ремонт",
            rang: "user",
          },
          {
            link: "/show",
            title: "Все ремонты",
            rang: "user",
          },
        ],
      },
      {
        link: "/analysis",
        title: "Журнал анализов",
        rang: "user",
        sublinks: [
          {
            link: "/create",
            title: "Записать анализ",
            rang: "user",
          },
          {
            link: "/show",
            title: "Все анализы",
            rang: "user",
          },
        ],
      },
    ],
  },
  {
    prefix: "/counteragents",
    links: [
      {
        link: "/create",
        title: "Добавить контрагента",
        rang: "user",
      },
      {
        link: "/all",
        title: "Все контрагенты",
        rang: "user",
      },
      {
        link: "/employees",
        title: "Все сотрудники",
        rang: "user",
      },
      {
        link: "/suppliers",
        title: "Все поставщики",
        rang: "user",
      },
    ],
  },
  {
    prefix: "/orders",
    links: [
      { link: "/all", title: "Все заказы", rang: "user" },
      { link: "/create", title: "Создать заказ", rang: "user" },
      // {
      //   link: "/return",
      //   title: "Возврат товаров от покупателя",
      //   rang: "user",
      // },
    ],
  },
  {
    prefix: "/counters",
    links: [
      {
        link: "/all",
        title: "Все кассы/счета",
        rang: "user",
      },

      {
        link: "/payment",
        title: "Платёж в кассу <-",
        rang: "user",
        sublinks: [
          {
            link: "/counteragent-to-counter",
            title: "Принять платеж от контрагента",
            rang: "user",
          },
          {
            link: "/to-counter",
            title: "Принять платеж(без контрагента)",
            rang: "user",
          },
        ],
      },
      {
        link: "/payment",
        title: "Платёж из кассы ->",
        rang: "user",
        sublinks: [
          {
            link: "/counter-to-counteragent",
            title: "Сделать выплату контрагенту",
            rang: "user",
          },
          {
            link: "/from-counter",
            title: "Произвести выплату(без контрагента)",
            rang: "user",
          },
        ],
      },
      {
        link: "/counter-to-counter",
        title: "Сделать перевод между кассами/счетами",
        rang: "user",
      },
      {
        link: "/payment/add-on-balance-of-counteragent",
        title: "Сделать начисление на баланс контрагента",
        rang: "user",
      },
    ],
  },
];

export default groupsOfLinks;
