import { connect } from "react-redux";
import EditReceiptOrderScreen from "./EditReceiptOrderScreen";
import { editReceiptOrder } from "src/storehouse/redux/actions";

export default connect(
  (state) => {
    return {
      profile: state.auth.profile,
      counteragents: state.counteragents.counteragents,
      storehouse: state.storehouse.storehouse,
      receiptOrders: state.storehouse.receiptOrders,
    };
  },
  { editReceiptOrder }
)(EditReceiptOrderScreen);
