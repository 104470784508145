import { connect } from "react-redux";
import ShowAllOrdersScreen from "./ShowAllOrdersScreen";
import {
  cancelOrder,
  shipOrder,
  cancelShipOrder,
} from "src/orders/redux/actions";

export default connect(
  (state) => {
    return {
      orders: state.orders.orders,
      profile: state.auth.profile,
    };
  },
  {
    cancelOrder,
    shipOrder,
    cancelShipOrder,
  }
)(ShowAllOrdersScreen);
