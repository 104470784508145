import { connect } from "react-redux";
import ShowCounteragentPaymentsScreen from "./ShowCounteragentPaymentsScreen";
import { cancelPayment, verifyPayment } from "src/counters/redux/actions";
import { getCounteragentPayments } from "src/counteragents/redux/actions";

export default connect(
  (state) => {
    return {
      counteragentPayments: state.counteragents.counteragentPayments,
      profile: state.auth.profile,
      selectedCounteragent: state.counteragents.selectedCounteragent,
    };
  },
  { cancelPayment, verifyPayment, getCounteragentPayments }
)(ShowCounteragentPaymentsScreen);
