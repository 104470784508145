import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Button,
  message,
  InputNumber,
  Space,
  Spin,
} from "antd";
import SelectorWithAddingOptions from "src/common/components/SelectorWithAddingOptions";

import DatePicker from "src/common/components/DatePicker";
import BackRouteButton from "src/common/components/BackRouteButton";

const { Option } = Select;

export default ({ paymentToCounter, profile, counters }) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);

  const onFinish = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;
    const { _id, title } =
      counters.find((c) => c._id === values.counter1) || {};
    values.counter1 = { id: _id, title };
    values.to = _id;

    const result = await paymentToCounter(values);
    if (result.success) {
      form.resetFields();
      message.success("Платёж принят!");
      history.goBack();
    } else {
      message.error(result.message || "Не удалось принять платёж!");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!counters) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [counters]);

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <BackRouteButton />
        <h4 className="common-title--large">Принять платёж в кассу</h4>
        <Form
          requiredMark={false}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            name="date"
            rules={[
              {
                required: true,
                message: "Пожалуйста, выберите дату!",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Назначение"
            style={{ width: "90%", margin: "0px" }}
            name="title"
            rules={[
              {
                required: true,
                message: "Пожалуйста, выберите назначение платежа!",
              },
            ]}
          >
            <SelectorWithAddingOptions
              placeholder="Назначение платежа"
              listOfOptions={["продажа материальных средств", "продажа услуг"]}
              onChange={(value) => form.setFieldsValue({ title: value })}
            />
          </Form.Item>
          <Space.Compact block size="small">
            <Form.Item
              style={{ width: "50%", margin: "0px" }}
              name="sum"
              rules={[
                {
                  required: true,
                  message: "Введите сумму платежа!",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} placeholder="Сумма" />
            </Form.Item>
            <Form.Item
              style={{ width: "40%", margin: "0px" }}
              name="type"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, выберите способ платежа!",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Способ платежа"
                optionFilterProp="children"
              >
                <Option value="наличные">
                  <h4 className="option-title-font">наличные</h4>
                </Option>
                <Option value="банковский счёт">
                  <h4 className="option-title-font">банковский счёт</h4>
                </Option>
                <Option value="перевод на карту">
                  <h4 className="option-title-font">перевод на карту</h4>
                </Option>
              </Select>
            </Form.Item>
          </Space.Compact>

          <br />
          <Form.Item
            label="Касса/Счёт для зачисления"
            name="counter1"
            rules={[
              {
                required: true,
                message: "Выберите кассу/счёт для зачисления!",
              },
            ]}
          >
            <Select
              size="small"
              placeholder="Касса/счёт"
              optionFilterProp="children"
            >
              {counters &&
                counters.map((counter) => (
                  <Option key={counter._id} value={counter._id}>
                    <h4 className="option-title-font">{counter.title}</h4>
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item label="Комментарий" name="comment">
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Button type="primary" block size="medium" htmlType="submit">
              Принять платёж
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
