import { connect } from "react-redux";
import ShowAllRepairsScreen from "./ShowAllRepairsScreen";
import { cancelRepair, editRepair } from "src/processing/redux/actions";

export default connect(
  (state) => {
    return {
      repairs: state.processing.repairs,
      profile: state.auth.profile,
    };
  },
  {
    cancelRepair,
    editRepair,
  }
)(ShowAllRepairsScreen);
