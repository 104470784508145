import { connect } from "react-redux";

import CabinetScreen from "./CabinetScreen";

export default connect((state) => {
  return {
    profile: state.auth.profile,
    actions: state.auth.actions,
  };
})(CabinetScreen);
