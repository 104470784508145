import React from "react";
import { RightOutlined } from "@ant-design/icons";
import { Collapse, Button } from "antd";
import { Link } from "react-router-dom";

import "./index.css";
const { Panel } = Collapse;

function prepareSublinks(link, profile, prefix) {
  return (
    <Collapse size="large" ghost key={link.link}>
      <Panel layout="vertical" header={link.title} key={link.title}>
        {link.sublinks.map((sublink, index) => {
          return sublink.rang === "admin" && profile.rang === "admin" ? (
            <Link
              to={`${prefix}${link.link}${sublink.link}`}
              key={sublink.link + Math.random()}
            >
              <Button type="text" size="large">
                {sublink.title}
                <RightOutlined />
              </Button>
            </Link>
          ) : sublink.rang === "user" ? (
            <Link
              to={`${prefix}${link.link}${sublink.link}`}
              key={sublink.link + Math.random()}
            >
              <Button type="text" size="large">
                {sublink.title}
                <RightOutlined />
              </Button>
            </Link>
          ) : null;
        })}
      </Panel>
    </Collapse>
  );
}

export default ({ values, profile }) => {
  return (
    <div className="common-container">
      <div className="routepage__link-container">
        {values.links.map((link) => {
          return link.rang === "admin" && profile.rang === "admin" ? (
            link.sublinks ? (
              prepareSublinks(link, profile, values.prefix)
            ) : (
              <Link to={`${values.prefix}${link.link}`} key={link.link}>
                <Button type="text" size="large">
                  <div className="routpage__title__container">
                    <p className="routpage__link__title">{link.title}</p>
                    <RightOutlined />
                  </div>
                </Button>
              </Link>
            )
          ) : link.rang === "user" ? (
            link.sublinks ? (
              prepareSublinks(link, profile, values.prefix)
            ) : (
              <Link to={`${values.prefix}${link.link}`} key={link.link}>
                <Button type="text" size="large">
                  <div className="routpage__title__container">
                    <p className="routpage__link__title">{link.title}</p>
                    <RightOutlined />
                  </div>
                </Button>
              </Link>
            )
          ) : null;
        })}
      </div>
    </div>
  );
};
