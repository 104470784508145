import { connect } from "react-redux";
import WriteOffScreen from "./WriteOffScreen";
import { writeOffStorehouseInstance } from "src/storehouse/redux/actions";

export default connect(
  (state) => {
    return {
      profile: state.auth.profile,
    };
  },
  {
    writeOffStorehouseInstance,
  }
)(WriteOffScreen);
