import { connect } from "react-redux";
import ShowCounteragentReceiptOrdersScreen from "./ShowCounteragentReceiptOrdersScreen";
import { cancelReceiptOrder } from "src/storehouse/redux/actions";
import { getCounteragentReceiptOrders } from "src/counteragents/redux/actions";

export default connect(
  (state) => {
    return {
      selectedCounteragent: state.counteragents.selectedCounteragent,
      counteragentReceiptOrders: state.counteragents.counteragentReceiptOrders,
      profile: state.auth.profile,
    };
  },
  {
    cancelReceiptOrder,
    getCounteragentReceiptOrders,
  }
)(ShowCounteragentReceiptOrdersScreen);
