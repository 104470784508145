import React, { useEffect, useState } from "react";
import { Collapse } from "antd";
import Skeleton from "src/common/components/Skeleton";
import commonFunc from "src/utils/commonFunc";
import { UserOutlined } from "@ant-design/icons";

import "./index.css";

const { Panel } = Collapse;
const { formatDate } = commonFunc;

export default ({ actions, profile }) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!actions) {
      setIsLoading(true);
      return;
    } else {
      setIsLoading(false);
    }
  }, [actions]);

  return (
    <div className="common-container">
      {isLoading ? (
        <Skeleton />
      ) : (
        <div>
          <Collapse size="large" ghost>
            <Panel
              layout="vertical"
              header="Последние действия всех пользователей:"
            >
              {actions.map((action) => {
                return (
                  <h2 key={action._id} className="action-title">
                    {formatDate(action.date)} | {action.title} |{" "}
                    <UserOutlined
                      style={{
                        fontSize: "12px",
                        border: "1px solid #ccc",
                        borderRadius: "50%",
                      }}
                    />{" "}
                    : {action.creator.username}
                  </h2>
                );
              })}
            </Panel>
          </Collapse>
          <Collapse size="large" ghost>
            <Panel layout="vertical" header="Ваши последние действия:">
              {actions.map((action) => {
                return action.creator._id === profile._id ? (
                  <h2 key={action._id} className="action-title">
                    {formatDate(action.date)} | {action.title}
                  </h2>
                ) : null;
              })}
            </Panel>
          </Collapse>
        </div>
      )}
    </div>
  );
};
