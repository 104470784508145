import { connect } from "react-redux";
import PaymentCounterToCounterScreen from "./PaymentCounterToCounterScreen";
import { paymentCounterToCounter } from "src/counters/redux/actions";

export default connect(
  (state) => {
    return {
      profile: state.auth.profile,
      counters: state.counters.counters,
    };
  },
  { paymentCounterToCounter }
)(PaymentCounterToCounterScreen);
