import React, { useState, useEffect } from "react";
import commonFunc from "src/utils/commonFunc";
import PrintOrderPopup from "src/orders/components/PrintOrderPopup";
import BackRouteButton from "src/common/components/BackRouteButton";

import { useHistory } from "react-router-dom";

import {
  Table,
  Card,
  Modal,
  Button,
  Space,
  Input,
  message,
  Radio,
  Spin,
} from "antd";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  CarOutlined,
  CreditCardOutlined,
  FieldNumberOutlined,
  CalendarOutlined,
  PrinterOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";

import "./index.css";

const { formatDate } = commonFunc;

export default ({
  counteragentOrders,
  cancelOrder,
  profile,
  shipOrder,
  cancelShipOrder,
  selectedCounteragent,
  getCounteragentOrders,
}) => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [print, handlePrint] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [filter, setFilter] = useState("all");
  const [dataSource, setDataSourse] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [columnsForModal, setColumnsForModal] = useState([]);
  const [columns, setColumns] = useState([]);
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setFilter(value);
  };

  const filterOrders = (filter, counteragentOrders, searchValue) => {
    let filteredData = counteragentOrders || [];

    if (searchValue) {
      filteredData = filteredData.filter((order) => {
        const searchValueLower = searchValue.toLowerCase();
        const searchNumber = parseFloat(searchValue);

        return (
          order.counteragent.firstname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          order.counteragent.lastname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          order.counteragent.additionalName
            ?.toLowerCase()
            .includes(searchValueLower) ||
          order.counteragent.firm?.toLowerCase().includes(searchValueLower) ||
          order?.comment?.toLowerCase().includes(searchValueLower) ||
          order.serial === searchNumber ||
          order.totalWeight === searchNumber ||
          order.totalSum === searchNumber
        );
      });
    }

    switch (filter) {
      case "opened":
        return filteredData.filter((order) => order.state === "opened");
      case "complete":
        return filteredData.filter((order) => order.state === "complete");
      case "cancelled":
        return filteredData.filter((order) => order.cancelled);
      case "notPayed":
        return filteredData.filter((order) => !order.paid && !order.cancelled);
      case "notShipped":
        return filteredData.filter(
          (order) => !order.shipped && !order.cancelled
        );
      case "withReturns":
        return filteredData.filter(
          (order) => order.returns.length > 0 && !order.cancelled
        );
      default:
        return filteredData;
    }
  };

  const prepareDataSource = (data) => {
    const sortedData = [...data].sort((a, b) => b.serial - a.serial);

    return sortedData.map((order, index) => ({
      ...order,
      key: order._id,
      date: formatDate(order.date).slice(0, 100),
      counteragentName: `${order.counteragent.firm || ""} ${
        order.counteragent.lastname || ""
      } ${order.counteragent.firstname || ""}
    ${order.counteragent.additionalName || ""}`,
      driverName: order.driver
        ? `${order.driver.firm || ""} ${order.driver.lastname || ""}
    ${order.driver.firstname || ""} ${order.driver.additionalName || ""}`
        : "",
      deliveryAddress: order.deliveryAddress
        ? `${order.deliveryAddress || ""}`
        : "",
      deliveryCost: order.deliveryCost ? `${order.deliveryCost || ""}` : "",
      totalSumPaid: `${order.totalSumPaid} / ${order.totalSum}`,
      payments: order.payments
        .map((payment) => {
          if (!payment.cancelled) {
            return {
              ...payment,
            };
          }
          return null;
        })
        .filter(Boolean),
      instances: order.instances.map((instance, idx) => ({
        ...instance,
        key: `${order._id}-${idx}`,
        title: instance.title.replace(/пшеничная|Хлебопекарная/g, ""),
        cost: Number((instance.price * instance.weight).toFixed(2)),
      })),
    }));
  };

  const handleEditOrder = async (order) => {
    history.push({
      pathname: `/orders/edit`,
      state: {
        order,
      },
    });
  };

  const handleCancelOrder = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;
    var result = await cancelOrder(values);

    if (result.success) {
      await getCounteragentOrders(selectedCounteragent);
      message.success("Заказ успешно отменён!");
      setLoading(false);
    } else {
      message.error(result.message || "Не удалось отменить заказ!");
      setLoading(false);
    }
  };
  const handleShipOrder = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;
    var result = await shipOrder(values);

    if (result.success) {
      await getCounteragentOrders(selectedCounteragent);
      message.success("Заказ успешно отгружен!");
      setLoading(false);
    } else {
      message.error(result.message || "Не удалось отгрузить заказ!");
      setLoading(false);
    }
  };
  const handleCancelShipOrder = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;
    var result = await cancelShipOrder(values);

    if (result.success) {
      await getCounteragentOrders(selectedCounteragent);
      message.success("Отгрузка заказа отменена!");
      setLoading(false);
    } else {
      message.error(result.message || "Не удалось отменить отгрузку!");
      setLoading(false);
    }
  };

  const handlePayForOrder = async (order) => {
    history.push({
      pathname: `/counters/order`,
      state: { order },
    });
  };

  const showConfirmCancel = (order) => {
    Modal.confirm({
      title: "Вы уверены, что хотите отменить этот заказ?",
      icon: <ExclamationCircleOutlined />,
      content: "Это действие не может быть отменено.",
      okText: "Да, отменить",
      okType: "danger",
      cancelText: "Отмена",
      onOk() {
        handleCancelOrder(order);
      },
      onCancel() {
        console.log("Cancelled");
      },
    });
  };
  const showConfirmShipping = (order) => {
    Modal.confirm({
      title: "Вы уверены, что хотите отгрузить заказ?",
      icon: <ExclamationCircleOutlined />,
      content: 'Это действие изменяет статус заказа на "отгруженный".',
      okText: "Да, отгрузить",
      okType: "danger",
      cancelText: "Отмена",
      onOk() {
        handleShipOrder(order);
      },
      onCancel() {
        console.log("Cancelled");
      },
    });
  };
  const showConfirmCancelShipping = (order) => {
    Modal.confirm({
      title: "Вы уверены, что хотите отменить отгрузку?",
      icon: <ExclamationCircleOutlined />,
      content: 'Это действие изменяет статус заказа на "неотгруженный".',
      okText: "Да, отменить",
      okType: "danger",
      cancelText: "Отмена",
      onOk() {
        handleCancelShipOrder(order);
      },
      onCancel() {
        console.log("Cancelled");
      },
    });
  };

  const showModal = (order) => {
    setSelectedOrder(order);
    setIsModalVisible(true);
  };

  const columnsForModalofPayments = [
    {
      title: (
        <div>
          <FieldNumberOutlined />/<CalendarOutlined />
        </div>
      ),
      dataIndex: "serial",
      key: "serial",
      render: (_, record) => (
        <span className="column-content-font">
          {record.serial} / {formatDate(record.date).slice(0, 20)}
        </span>
      ),
    },
    {
      title: <span className="column-title-font">Сумма</span>,
      dataIndex: "sum",
      key: "sum",
      render: (text, record) =>
        record.cancelled ? (
          <span className="table-title-font">ОТМЕНЁН</span>
        ) : (
          <span className="column-content-font">{text} руб.</span>
        ),
    },
    {
      title: <span className="column-title-font">Куда</span>,
      dataIndex: "counter1",
      key: "counter1",
      render: (text) => (
        <span className="column-content-font">{text.title} </span>
      ),
    },
    {
      title: <span className="column-title-font">Способ оплаты</span>,
      dataIndex: "type",
      key: "type",
      render: (text) => <span className="column-content-font">{text}</span>,
    },
  ];

  const OrderModalContent = () => {
    if (!selectedOrder) {
      return null;
    }

    const prepareOrderForPrint = (order) => {
      var originalOrder = counteragentOrders.find((o) => o._id === order._id);
      order.instances = originalOrder.instances;
      return order;
    };

    const {
      date,
      serial,
      counteragentName,
      instances,
      totalSumPaid,
      totalSum,
      totalWeight,
      comment,
      cancelled,
      shipped,
      paid,
      payments,
      driverName,
      deliveryAddress,
      deliveryCost,
    } = selectedOrder;
    return (
      <Card
        size="small"
        title={`Заказ №${serial}`}
        extra={
          <div className="common-modal__buttons-container">
            <span className="column-title-font">
              Дата заказа: {cancelled ? "ОТМЕНЕН" : date.slice(0, 20)}
            </span>
            <Button
              disabled={cancelled}
              className="common-table-button"
              type="link"
              size="small"
              icon={<PrinterOutlined />}
              onClick={() => {
                var preparedOrder = prepareOrderForPrint(selectedOrder);
                setSelectedOrder(preparedOrder);
                handlePrint(true);
              }}
            />
          </div>
        }
      >
        <span className="column-title-font">Покупатель:</span>
        <span className="column-content-font">{counteragentName}</span>
        <br />
        <br />
        <Table
          dataSource={instances}
          columns={columnsForModal}
          pagination={false}
          size="small"
        />
        <div className="common-modal__info-block">
          <div className="common-modal__info-container">
            <span className="column-title-font">Общий вес:</span>
            <span className="column-content-font">{totalWeight} кг</span>
          </div>

          <div className="common-modal__info-container">
            <span className="column-title-font">Итого:</span>
            <span className="column-content-font">{totalSum} руб.</span>
          </div>
          <div className="common-modal__info-container">
            <span className="column-title-font">Оплачено:</span>
            {paid ? (
              <span className="column-title-font" style={{ color: "green" }}>
                Да
              </span>
            ) : (
              <span className="column-content-font" style={{ color: "red" }}>
                Нет
              </span>
            )}
          </div>
          <div className="common-modal__info-container">
            <span className="column-title-font">Всего оплачено:</span>
            {cancelled ? (
              <span className="column-title-font" style={{ color: "red" }}>
                Отменен
              </span>
            ) : (
              <span className="column-content-font">{totalSumPaid} руб.</span>
            )}
          </div>
          <div className="common-modal__info-container">
            <span className="column-title-font">Отгружено:</span>
            {shipped ? (
              <span className="column-title-font" style={{ color: "green" }}>
                Да
              </span>
            ) : (
              <span className="column-content-font" style={{ color: "red" }}>
                Нет
              </span>
            )}
          </div>
          {driverName && (
            <div className="common-modal__info-container">
              <span className="column-title-font">Водитель:</span>
              <span className="column-title-font">{driverName}</span>
            </div>
          )}
          {deliveryAddress && (
            <div className="common-modal__info-container">
              <span className="column-title-font">Адрес доставки:</span>
              <span className="column-title-font">{deliveryAddress}</span>
            </div>
          )}
          {deliveryCost && (
            <div className="common-modal__info-container">
              <span className="column-title-font">Стоимость доставки:</span>
              <span className="column-title-font">{deliveryCost} руб.</span>
            </div>
          )}
          {comment && (
            <div className="common-modal__info-container">
              <span className="column-title-font">Комментарий:</span>
              <span className="column-content-font">{comment}</span>
            </div>
          )}
          <h3 className="common-modal-title">Платежи:</h3>
          <Table
            dataSource={payments}
            columns={columnsForModalofPayments}
            pagination={false}
            size="small"
          />
        </div>
        <PrintOrderPopup
          order={selectedOrder}
          print={print}
          handlePrint={handlePrint}
        />
      </Card>
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const columns = [
      {
        title: (
          <div>
            <FieldNumberOutlined /> / <CalendarOutlined />
          </div>
        ),
        dataIndex: "serial",

        key: "serial",
        render: (_, record) => (
          <span className="column-content-font">
            {record.serial} / {record.date}
          </span>
        ),
      },
      {
        title: <span className="table-title-font">Вес</span>,
        dataIndex: "totalWeight",
        key: "totalWeight",
        render: (text) => <span className="column-content-font">{text}кг</span>,
      },
      {
        title: <span className="table-title-font">Сумма</span>,
        dataIndex: "totalSum",
        key: "totalSum",
        render: (text, record) =>
          record.cancelled ? (
            <span className="table-title-font">ОТМЕНЁН</span>
          ) : (
            <span className="column-content-font">{text} р.</span>
          ),
      },

      {
        title: <span className="table-title-font">Действия</span>,
        key: "action",
        render: (text, record) => (
          <Space>
            <Button
              disabled={record.state === "complete" || record.cancelled}
              className="common-table-button"
              type="link"
              size="small"
              icon={
                <CarOutlined
                  style={{
                    color: record.cancelled
                      ? "inherit"
                      : record.shipped
                      ? "green"
                      : "red",
                  }}
                />
              }
              onClick={() => {
                if (record.shipped) {
                  showConfirmCancelShipping(record);
                } else {
                  showConfirmShipping(record);
                }
              }}
            />
            <Button
              disabled={
                record.state === "complete" || record.cancelled || record.paid
              }
              className="common-table-button"
              type="link"
              size="small"
              icon={
                <CreditCardOutlined
                  style={{
                    color: record.cancelled
                      ? "inherit"
                      : record.paid
                      ? "green"
                      : "red",
                  }}
                />
              }
              onClick={() => handlePayForOrder(record)}
            />
            <Button
              className="common-table-button"
              size="small"
              type="link"
              onClick={() => showModal(record)}
              icon={<EyeOutlined />}
            />
            <Button
              disabled={
                record.state === "complete" ||
                record.cancelled ||
                record.paid ||
                record.shipped ||
                record.returns.length > 0 ||
                record.payments.length > 0
              }
              className="common-table-button"
              type="link"
              size="small"
              icon={<EditOutlined />}
              onClick={() => handleEditOrder(record)}
            />
            <Button
              disabled={
                record.state === "complete" ||
                record.cancelled ||
                record.paid ||
                record.shipped ||
                record.returns.length > 0 ||
                record.payments.length > 0
              }
              className="common-table-button"
              type="link"
              size="small"
              icon={<DeleteOutlined />}
              onClick={() => showConfirmCancel(record)}
              danger
            />
          </Space>
        ),
      },
      {
        dataIndex: "state",
        key: "state",
        render: (text, record) => (
          <FileDoneOutlined
            style={{
              color:
                record.paid && !record.shipped
                  ? "green"
                  : !record.paid && record.shipped
                  ? "red"
                  : record.cancelled
                  ? "grey"
                  : record.state === "complete"
                  ? "yellow"
                  : "inherit",
            }}
          />
        ),
      },
    ];
    const columnsForModal = [
      {
        title: <span className="column-title-font">Наименование</span>,
        dataIndex: "title",
        key: "title",
        render: (text) => <span className="column-content-font">{text}</span>,
      },
      {
        title: <span className="column-title-font">Вес</span>,
        dataIndex: "weight",
        key: "weight",
        render: (text) => (
          <span className="column-content-font">{text} кг</span>
        ),
      },
      {
        title: <span className="column-title-font">Цена</span>,
        dataIndex: "price",
        key: "price",
        render: (text) => (
          <span className="column-content-font">{text} руб</span>
        ),
      },
    ];
    const counteragentColumn = {
      title: <span className="column-title-font">Контрагент</span>,
      dataIndex: "counteragentName",
      key: "counteragentName",
      render: (text) => <span className="column-content-font">{text}</span>,
    };
    const costColumn = {
      title: <span className="column-title-font">Стоимость</span>,
      dataIndex: "cost",
      key: "cost",
      render: (text) => <span className="column-content-font">{text} руб</span>,
    };

    if (windowWidth > 375) {
      columns.splice(1, 0, counteragentColumn);
      columnsForModal.push(costColumn);
    }

    setColumns(columns);
    setColumnsForModal(columnsForModal);
  }, [windowWidth]);

  useEffect(() => {
    if (!counteragentOrders) {
      setLoading(true);
      return;
    }
    const filtered = filterOrders(filter, counteragentOrders, searchValue);
    const dataSource = prepareDataSource(filtered);
    setDataSourse(dataSource);

    setLoading(false);
  }, [filter, counteragentOrders, searchValue]);

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <BackRouteButton />

        <span className="common-info-title">
          Все заказы контрагента: {selectedCounteragent.nameInfo}
        </span>
        <Input.Search
          placeholder="Поиск"
          onChange={handleSearchChange}
          style={{ marginBottom: 8 }}
          size="small"
        />
        <Radio.Group
          onChange={handleFilterChange}
          value={filter}
          style={{ marginBottom: 16 }}
          size="small"
        >
          <Radio.Button value="all">Все</Radio.Button>
          <Radio.Button value="opened">Открытые</Radio.Button>
          <Radio.Button value="complete">Завершенные</Radio.Button>
          <Radio.Button value="cancelled">Отмененные</Radio.Button>
          <Radio.Button value="notPayed">Неоплаченые</Radio.Button>
          <Radio.Button value="notShipped">Неотгруженные</Radio.Button>
          <Radio.Button value="withReturns">С возвратами</Radio.Button>
        </Radio.Group>

        <Table
          pagination={{ pageSize: 20 }}
          dataSource={dataSource}
          columns={columns}
        />
        <Modal
          open={isModalVisible}
          bodyStyle={{ marginTop: "20px" }}
          onCancel={handleCancel}
          footer={null}
        >
          <OrderModalContent />
        </Modal>
      </Spin>
    </div>
  );
};
