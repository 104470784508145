import createReducer from "src/utils/createReducer";
import { SET_ORDERS } from "./types";

const INITIAL_STATE = {
  orders: false,
};
export default createReducer(INITIAL_STATE, {
  [SET_ORDERS]: (state, action) => {
    return {
      ...state,
      orders: action.orders,
    };
  },
});
