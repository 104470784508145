import { connect } from "react-redux";
import CreateReceiptOrderScreen from "./CreateReceiptOrderScreen";
import { createReceiptOrder } from "src/storehouse/redux/actions";

export default connect(
  (state) => {
    return {
      profile: state.auth.profile,
      counteragents: state.counteragents.counteragents,
      storehouse: state.storehouse.storehouse,
    };
  },
  { createReceiptOrder }
)(CreateReceiptOrderScreen);
