import { connect } from "react-redux";
import CreateRepairScreen from "./CreateRepairScreen";
import { createRepair } from "src/processing/redux/actions";

export default connect(
  (state) => {
    return {
      profile: state.auth.profile,
    };
  },
  { createRepair }
)(CreateRepairScreen);
