import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Table, Button, Space } from "antd";
import { EditOutlined } from "@ant-design/icons";
import BackRouteButton from "src/common/components/BackRouteButton";

export default ({ pricesDinMel }) => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleEditPrice = async (price) => {
    history.push({
      pathname: `/storehouse/settings/din-mel/prices/edit`,
      state: { price },
    });
  };

  const columns = [
    {
      title: <span className="column-title-font">Название товара</span>,
      dataIndex: "title",
      key: "title",
      render: (text) => <span className="column-content-font">{text}</span>,
    },
    {
      title: <span className="column-title-font"> Цена на din-mel.ru</span>,
      dataIndex: "price",
      key: "price",
      render: (text) => (
        <span className="column-content-font">{text} руб/1 кг</span>
      ),
    },
    {
      title: "Действия",
      key: "action",
      render: (text, record) => (
        <Space>
          <Button
            className="common-table-button"
            type="link"
            size="small"
            icon={<EditOutlined />}
            onClick={() => handleEditPrice(record)}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (!pricesDinMel) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [pricesDinMel]);

  return (
    <div className="common-container">
      <BackRouteButton />
      <h4 className="common-title--large">Цены товаров на din-mel.ru</h4>
      <Table
        columns={columns}
        dataSource={pricesDinMel}
        rowKey="_id"
        loading={loading}
        pagination={false}
      />
    </div>
  );
};
