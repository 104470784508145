import { connect } from "react-redux";
import CreateAnalysisScreen from "./CreateAnalysisScreen";
import { createAnalysis } from "src/processing/redux/actions";

export default connect(
  (state) => {
    return {
      profile: state.auth.profile,
    };
  },
  { createAnalysis }
)(CreateAnalysisScreen);
