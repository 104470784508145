import { connect } from "react-redux";
import EditStorehouseCategoryScreen from "./EditStorehouseCategoryScreen";
import { editCategory } from "src/storehouse/redux/actions";

export default connect(
  (state) => {
    return {
      profile: state.auth.profile,
    };
  },
  {
    editCategory,
  }
)(EditStorehouseCategoryScreen);
