import React, { useState, useEffect } from "react";
import commonFunc from "src/utils/commonFunc";
import BackRouteButton from "src/common/components/BackRouteButton";

import { useHistory } from "react-router-dom";

import {
  Table,
  Card,
  Modal,
  Button,
  Space,
  Input,
  message,
  Radio,
  Spin,
} from "antd";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FieldNumberOutlined,
  CalendarOutlined,
} from "@ant-design/icons";

const { formatDate } = commonFunc;

export default ({
  counteragentShifts,
  cancelShift,
  profile,
  selectedCounteragent,
  getCounteragentShifts,
}) => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedShift, setSelectedShift] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [filter, setFilter] = useState("all");
  const [dataSource, setDataSource] = useState([]);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setFilter(value);
  };

  const filterShifts = (filter, counteragentShifts, searchValue) => {
    let filteredData = counteragentShifts || [];

    if (searchValue) {
      filteredData = filteredData.filter((shift) => {
        const searchValueLower = searchValue.toLowerCase();
        const searchNumber = parseFloat(searchValue);

        return (
          shift.workers.senior.firstname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.senior.lastname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.senior.additionalName
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.junior1.firstname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.junior1.lastname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.junior1.additionalName
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.junior2.firstname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.junior2.lastname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.junior2.additionalName
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.junior3.firstname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.junior3.lastname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.junior3.additionalName
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.porter1.firstname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.porter1.lastname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.porter1.additionalName
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.porter2.firstname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.porter2.lastname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.porter2.additionalName
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.porter3.firstname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.porter3.lastname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.porter3.additionalName
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.porter4.firstname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.porter4.lastname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.porter4.additionalName
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.porter5.firstname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.porter5.lastname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.porter5.additionalName
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.porter6.firstname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.porter6.lastname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.porter6.additionalName
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.loader.firstname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.loader.lastname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.loader.additionalName
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.helperOfTechnologist.firstname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.helperOfTechnologist.lastname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.helperOfTechnologist.additionalName
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.grainstoreWorker1.firstname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.grainstoreWorker1.lastname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.grainstoreWorker1.additionalName
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.grainstoreWorker2.firstname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.grainstoreWorker2.lastname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.grainstoreWorker2.additionalName
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.packer?.firstname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.packer?.lastname
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.workers.packer?.additionalName
            ?.toLowerCase()
            .includes(searchValueLower) ||
          shift.serial === searchNumber ||
          shift.totalWeight === searchNumber
        );
      });
    }

    switch (filter) {
      case "dayShift":
        return filteredData.filter(
          (rO) => rO.period === "day" && !rO.cancelled
        );
      case "nightShift":
        return filteredData.filter(
          (rO) => rO.period === "night" && !rO.cancelled
        );
      case "withRepair":
        return filteredData.filter((rO) => rO.repairTime > 0 && !rO.cancelled);
      case "cancelled":
        return filteredData.filter((rO) => rO.cancelled);
      default:
        return filteredData;
    }
  };
  const prepareName = (worker) => {
    if (!worker) {
      return "";
    }

    const { firstname = "", lastname = "", additionalName = "" } = worker;

    return `${firstname} ${lastname} ${additionalName}`.trim();
  };

  const prepareDataSource = (data) => {
    const sortedData = [...data].sort((a, b) => b.serial - a.serial);

    return sortedData.map((shift, index) => ({
      ...shift,
      key: shift._id,
      date: formatDate(shift.date).slice(0, 10),
      senior: prepareName(shift.workers.senior),
      junior1: prepareName(shift.workers.junior1),
      junior2: prepareName(shift.workers.junior2),
      junior3: prepareName(shift.workers.junior3),
      porter1: prepareName(shift.workers.porter1),
      porter2: prepareName(shift.workers.porter2),
      porter3: prepareName(shift.workers.porter3),
      porter4: prepareName(shift.workers.porter4),
      porter5: prepareName(shift.workers.porter5),
      porter6: prepareName(shift.workers.porter6),
      loader: prepareName(shift.workers.loader),
      helperOfTechnologist: prepareName(shift.workers.helperOfTechnologist),
      grainstoreWorker1: prepareName(shift.workers.grainstoreWorker1),
      grainstoreWorker2: prepareName(shift.workers.grainstoreWorker2),
      packer: prepareName(shift.workers.packer),
      instances: shift.instances.map((instance, idx) => ({
        ...instance,
        percent: (instance.weight / shift.totalWeight) * 100,
        key: `${shift._id}-${idx}`,
      })),
    }));
  };

  const handleEditShift = async (shift) => {
    history.push({
      pathname: `/processing/shift/edit`,
      state: { shift },
    });
  };

  const handleCancelShift = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;
    var result = await cancelShift(values);

    if (result.success) {
      await getCounteragentShifts(selectedCounteragent);
      message.success("Смена успешно отменена!");
      setLoading(false);
    } else {
      message.error(result.message || "Не удалось отменить!");
      setLoading(false);
    }
  };

  const showConfirmCancel = (shift) => {
    Modal.confirm({
      title: "Вы уверены, что хотите отменить эту смену?",
      icon: <ExclamationCircleOutlined />,
      content: "Это действие не может быть отменено.",
      okText: "Да, отменить",
      okType: "danger",
      cancelText: "Отмена",
      onOk() {
        handleCancelShift(shift);
      },
      onCancel() {
        console.log("Cancelled");
      },
    });
  };

  const showModal = (shift) => {
    setSelectedShift(shift);
    setIsModalVisible(true);
  };

  const columnsForModal = [
    {
      title: <span className="column-title-font">Продукт</span>,
      dataIndex: "title",
      key: "title",
      render: (text) => <span className="column-content-font">{text}</span>,
    },
    {
      title: <span className="column-title-font">Произведено:</span>,
      dataIndex: "weight",
      key: "weight",
      render: (text) => <span className="column-content-font">{text} кг</span>,
    },
    {
      title: <span className="column-title-font">Процентовка:</span>,
      dataIndex: "percent",
      key: "percent",
      render: (text) => (
        <span className="column-content-font">{text.toFixed(1)} %</span>
      ),
    },
  ];

  const ShiftModalContent = () => {
    if (!selectedShift) {
      return null;
    }

    const {
      date,
      serial,
      senior,
      junior1,
      junior2,
      junior3,
      porter1,
      porter2,
      porter3,
      porter4,
      porter5,
      porter6,
      loader,
      helperOfTechnologist,
      grainstoreWorker1,
      grainstoreWorker2,
      packer,
      instances,
      totalWeight,
      comment,
      cancelled,
      period,
      workers,
      repairTime,
      type,
    } = selectedShift;

    return (
      <Card
        size="small"
        title={
          type !== "initialProducts"
            ? `Смена №${serial}`
            : "Ввод начального остатка"
        }
        extra={
          <span className="column-title-font">
            Дата: {cancelled ? "ОТМЕНЕНА" : date} -{" "}
            {type !== "initialProducts"
              ? period === "day"
                ? "дневная смена"
                : " ночная смена"
              : "ввод начального остатка"}
          </span>
        }
      >
        {type !== "initialProducts" && (
          <div className="common-modal__info-block">
            <span className="common-info-title">Ремонт:</span>
            <div>
              <span className="column-title-font">Затрачено часов:</span>
              <span className="column-content-font">{repairTime}</span>
            </div>
            <span className="common-info-title">Работники смены:</span>
            <div>
              <span className="column-title-font">Старший:</span>
              <span className="column-content-font">{senior} / </span>
              <span className="column-content-font" style={{ color: "green" }}>
                {" "}
                {workers.senior.profit} руб.
              </span>
            </div>
            <div>
              <span className="column-title-font">Младший №1:</span>
              <span className="column-content-font">{junior1} /</span>
              <span className="column-content-font" style={{ color: "green" }}>
                {" "}
                {workers.junior1.profit} руб.
              </span>
            </div>
            <div>
              <span className="column-title-font">Младший №2:</span>
              <span className="column-content-font">{junior2} /</span>
              <span className="column-content-font" style={{ color: "green" }}>
                {" "}
                {workers.junior2.profit} руб.
              </span>
            </div>
            {junior3 && (
              <div>
                <span className="column-title-font">Младший №3:</span>
                <span className="column-content-font">{junior3} /</span>
                <span
                  className="column-content-font"
                  style={{ color: "green" }}
                >
                  {" "}
                  {workers.junior3.profit} руб.
                </span>
              </div>
            )}
            {porter1 && (
              <div>
                <span className="column-title-font">Грузчик №1:</span>
                <span className="column-content-font">{porter1} /</span>
                <span
                  className="column-content-font"
                  style={{ color: "green" }}
                >
                  {" "}
                  {workers.porter1.profit} руб.
                </span>
              </div>
            )}
            {porter2 && (
              <div>
                <span className="column-title-font">Грузчик №2:</span>
                <span className="column-content-font">{porter2} /</span>
                <span
                  className="column-content-font"
                  style={{ color: "green" }}
                >
                  {" "}
                  {workers.porter2.profit} руб.
                </span>
              </div>
            )}
            {porter3 && (
              <div>
                <span className="column-title-font">Грузчик №3:</span>
                <span className="column-content-font">{porter3} /</span>
                <span
                  className="column-content-font"
                  style={{ color: "green" }}
                >
                  {" "}
                  {workers.porter3.profit} руб.
                </span>
              </div>
            )}
            {porter4 && (
              <div>
                <span className="column-title-font">Грузчик №4:</span>
                <span className="column-content-font">{porter4} /</span>
                <span
                  className="column-content-font"
                  style={{ color: "green" }}
                >
                  {" "}
                  {workers.porter4.profit} руб.
                </span>
              </div>
            )}
            {porter5 && (
              <div>
                <span className="column-title-font">Грузчик №5:</span>
                <span className="column-content-font">{porter5} /</span>
                <span
                  className="column-content-font"
                  style={{ color: "green" }}
                >
                  {" "}
                  {workers.porter5.profit} руб.
                </span>
              </div>
            )}
            {porter6 && (
              <div>
                <span className="column-title-font">Грузчик №6:</span>
                <span className="column-content-font">{porter6} /</span>
                <span
                  className="column-content-font"
                  style={{ color: "green" }}
                >
                  {" "}
                  {workers.porter6.profit} руб.
                </span>
              </div>
            )}
            {loader && (
              <div>
                <span className="column-title-font">Карщик:</span>
                <span className="column-content-font">{loader} /</span>
                <span
                  className="column-content-font"
                  style={{ color: "green" }}
                >
                  {" "}
                  {workers.loader.profit} руб.
                </span>
              </div>
            )}
            {helperOfTechnologist && (
              <div>
                <span className="column-title-font">Помощник технолога:</span>
                <span className="column-content-font">
                  {helperOfTechnologist} /
                </span>
                <span
                  className="column-content-font"
                  style={{ color: "green" }}
                >
                  {" "}
                  {workers.helperOfTechnologist.profit} руб.
                </span>
              </div>
            )}
            {grainstoreWorker1 && (
              <div>
                <span className="column-title-font">
                  Работник зерносклада №1:
                </span>
                <span className="column-content-font">
                  {grainstoreWorker1} /
                </span>
                <span
                  className="column-content-font"
                  style={{ color: "green" }}
                >
                  {" "}
                  {workers.grainstoreWorker1.profit} руб.
                </span>
              </div>
            )}
            {grainstoreWorker2 && (
              <div>
                <span className="column-title-font">
                  Работник зерносклада №2:
                </span>
                <span className="column-content-font">
                  {grainstoreWorker2} /
                </span>
                <span
                  className="column-content-font"
                  style={{ color: "green" }}
                >
                  {" "}
                  {workers.grainstoreWorker2.profit} руб.
                </span>
              </div>
            )}
            {packer && (
              <div>
                <span className="column-title-font">Упаковщик:</span>
                <span className="column-content-font">{packer} /</span>
                <span
                  className="column-content-font"
                  style={{ color: "green" }}
                >
                  {" "}
                  {workers.packer.profit} руб.
                </span>
              </div>
            )}
          </div>
        )}
        <Table
          dataSource={instances}
          columns={columnsForModal}
          pagination={false}
          size="small"
        />

        <div className="common-modal__info-block">
          <div>
            <span className="column-title-font">Всего произведено:</span>
            <span className="column-content-font">{totalWeight} кг</span>
          </div>

          <div>
            <span className="column-title-font">Комментарий:</span>
            <span className="column-content-font">{comment}</span>
          </div>
        </div>
      </Card>
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: (
        <div>
          <FieldNumberOutlined /> <CalendarOutlined />
        </div>
      ),
      dataIndex: "serial",
      key: "serial",
      render: (text, record) => (
        <span className="column-content-font">
          {record.serial} / {record.date} /
          {record.period === "day" ? "день" : " ночь"}
        </span>
      ),
    },
    {
      title: <span className="column-title-font">Произведено:</span>,
      dataIndex: "totalWeight",
      key: "totalWeight",
      render: (text, record) =>
        record.cancelled ? (
          <span className="column-title-font">отменена</span>
        ) : (
          <span className="column-content-font">{text}кг</span>
        ),
    },
    {
      title: <span className="column-title-font">Действия</span>,
      key: "action",
      render: (text, record) => (
        <Space>
          <Button
            className="common-table-button"
            size="small"
            type="link"
            onClick={() => showModal(record)}
            icon={<EyeOutlined />}
          />
          <Button
            disabled={record.cancelled || record.type === "initialProducts"}
            className="common-table-button"
            type="link"
            size="small"
            icon={<EditOutlined />}
            onClick={() => handleEditShift(record)}
          />
          <Button
            disabled={record.cancelled || record.type === "initialProducts"}
            className="common-table-button"
            type="link"
            size="small"
            icon={<DeleteOutlined />}
            onClick={() => showConfirmCancel(record)}
            danger
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (!counteragentShifts) {
      setLoading(true);
      return;
    }

    const filteredShifts = filterShifts(
      filter,
      counteragentShifts,
      searchValue
    );

    const dataSource = prepareDataSource(filteredShifts);
    setDataSource(dataSource);

    setLoading(false);
  }, [filter, counteragentShifts, searchValue]);

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <BackRouteButton />
        <span className="common-info-title">
          Все смены контрагента: {selectedCounteragent.nameInfo}
        </span>
        <Input.Search
          placeholder="Поиск"
          onChange={handleSearchChange}
          style={{ marginBottom: 8 }}
          size="small"
        />
        <Radio.Group
          onChange={handleFilterChange}
          value={filter}
          style={{ marginBottom: 16 }}
          size="small"
        >
          <Radio.Button value="all">Все</Radio.Button>
          <Radio.Button value="dayShift">Дневные</Radio.Button>
          <Radio.Button value="nightShift">Ночные</Radio.Button>
          <Radio.Button value="withRepair">С ремонтом</Radio.Button>
          <Radio.Button value="cancelled">Отменённые</Radio.Button>
        </Radio.Group>

        <Table
          rowClassName={(record) => {
            if (record.cancelled) return "cancelled";
            return "";
          }}
          pagination={{ pageSize: 20 }}
          dataSource={dataSource}
          columns={columns}
        />
        <Modal
          open={isModalVisible}
          bodyStyle={{ marginTop: "20px" }}
          onCancel={handleCancel}
          footer={null}
        >
          <ShiftModalContent />
        </Modal>
      </Spin>
    </div>
  );
};
