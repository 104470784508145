import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";

import Dashboard from "src/common/components/Dashboard";
import LogoSvg from "src/assets/icons/LogoSvg.svg";
import AvatarSVG from "src/assets/icons/AvatarSVG.svg";
import ScrollToTop from "src/utils/ScrollToTop";
import "./index.css";

export default ({ children, profile }) => {
  var arr = [];

  ScrollToTop();

  return profile ? (
    <div className="layout__container">
      <div className="layout__header">
        <div className="layout__logo-container">
          <LogoSvg className="layout__logo" />
        </div>

        <Link to="/cabinet" className="layout__userinfo">
          <AvatarSVG className="layout__avatar-icon" />
          <h1 className="layout__username">{profile.username}</h1>
        </Link>
      </div>

      <div className="layout__children">
        <InfiniteScroll dataLength={arr.length}>{children}</InfiniteScroll>
      </div>

      <div className="layout__footer-container">
        <Dashboard />
      </div>
    </div>
  ) : (
    <div className="layout__container">
      <div className="layout__for_login">{children}</div>
    </div>
  );
};
