import { connect } from "react-redux";
import ShowAllCounteragentsScreen from "./ShowAllCounteragentsScreen";
import {
  deleteCounteragent,
  getCounteragentPayments,
  getInitialCounteragentPayments,
  getCounteragentOrders,
  getInitialCounteragentOrders,
  getCounteragentReceiptOrders,
  getInitialCounteragentReceiptOrders,
  getCounteragentShifts,
  getInitialCounteragentShifts,
  setCounteragentOrders,
  setCounteragentReceiptOrders,
  setCounteragentShifts,
  setCounteragentPayments,
  setSelectedCounteragent,
} from "src/counteragents/redux/actions";

export default connect(
  (state) => {
    return {
      counteragents: state.counteragents.counteragents,
      profile: state.auth.profile,
      selectedCounteragent: state.counteragents.selectedCounteragent,
    };
  },
  {
    deleteCounteragent,
    getCounteragentPayments,
    getCounteragentOrders,
    getCounteragentReceiptOrders,
    getCounteragentShifts,
    setSelectedCounteragent,
    setCounteragentOrders,
    setCounteragentReceiptOrders,
    setCounteragentShifts,
    setCounteragentPayments,
    getInitialCounteragentPayments,
    getInitialCounteragentOrders,
    getInitialCounteragentReceiptOrders,
    getInitialCounteragentShifts,
  }
)(ShowAllCounteragentsScreen);
