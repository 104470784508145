import { connect } from "react-redux";
import EditOrderScreen from "./EditOrderScreen";
import { editOrder } from "src/orders/redux/actions";

export default connect(
  (state) => {
    return {
      profile: state.auth.profile,
      counteragents: state.counteragents.counteragents,
      storehouse: state.storehouse.storehouse,
      orders: state.orders.orders,
    };
  },
  { editOrder }
)(EditOrderScreen);
