import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Button,
  message,
  InputNumber,
  Space,
  Card,
  Table,
  Spin,
} from "antd";

import BackRouteButton from "src/common/components/BackRouteButton";
import DatePicker from "src/common/components/DatePicker";

const { Option } = Select;

export default ({ paymentCounterToCounteragent, profile, counters }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [receiptOrder, setReceiptOrder] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;
    values.title = "Оплата товарной накладной";

    const { _id, title } =
      counters.find((c) => c._id === values.counter1) || {};
    values.counter1 = { id: _id, title };

    values.from = _id;
    values.to = receiptOrder.counteragent.id;
    values.counteragent = receiptOrder.counteragent;
    values.receiptOrder = {
      id: receiptOrder._id,
      serial: receiptOrder.serial,
    };

    const result = await paymentCounterToCounteragent(values);
    if (result.success) {
      form.resetFields();
      message.success("Оплата произведена!");
      history.goBack();
    } else {
      message.error(result.message || "Не удалось оплатить!");
      setLoading(false);
    }
  };

  const columnsForModal = [
    {
      title: <span className="column-title-font">Наименование</span>,
      dataIndex: "title",
      key: "title",
      render: (text) => <span className="column-content-font">{text}</span>,
    },
    {
      title: <span className="column-title-font">Вес</span>,
      dataIndex: "weight",
      key: "weight",
      render: (text) => <span className="column-content-font">{text} кг</span>,
    },
    {
      title: <span className="column-title-font">Цена</span>,
      dataIndex: "price",
      key: "price",
      render: (text) => <span className="column-content-font">{text} руб</span>,
    },
  ];

  useEffect(() => {
    var receiptOrder = location.state.receiptOrder;

    receiptOrder.counteragentName = `${receiptOrder.counteragent.firm || ""} ${
      receiptOrder.counteragent.firstname || ""
    } ${receiptOrder.counteragent.lastname || ""}`;

    setReceiptOrder(receiptOrder);
    setLoading(false);
  }, []);

  return (
    <div className="common-container">
      <BackRouteButton />
      <h4 className="common-title--large">Оплатить товарную накладную</h4>
      <Spin spinning={loading}>
        <Card size="small" title={`Товарная накладная №${receiptOrder.serial}`}>
          <span className="column-title-font">
            Завезено:{" "}
            {receiptOrder.cancelled
              ? "ОТМЕНЕНА"
              : receiptOrder?.date?.slice(0, 5)}
          </span>

          <span className="column-title-font">Поставщик:</span>
          <span className="column-content-font">
            {receiptOrder.counteragentName}
          </span>
          <br />
          <br />
          <Table
            dataSource={receiptOrder.instances}
            columns={columnsForModal}
            pagination={false}
            size="small"
          />
          <div className="common-modal__info-block">
            <div>
              <span className="column-title-font">Общий вес:</span>
              <span className="column-content-font">
                {receiptOrder.totalWeight} кг
              </span>
            </div>

            <div>
              <span className="column-title-font">Итого:</span>
              <span className="column-content-font">
                {receiptOrder.totalSum} руб.
              </span>
            </div>
            <div>
              <span className="column-title-font">Оплачено:</span>
              {receiptOrder.cancelled ? (
                <span className="column-title-font" style={{ color: "red" }}>
                  Отменен
                </span>
              ) : (
                <span className="column-content-font">
                  {receiptOrder.totalSumPaid} руб.
                </span>
              )}
            </div>
            <div>
              <span className="column-title-font">Комментарий:</span>
              <span className="column-content-font">
                {receiptOrder.comment}
              </span>
            </div>
          </div>
        </Card>
        <br />

        <Form
          requiredMark={false}
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label="Дата платежа"
            name="date"
            rules={[
              {
                required: true,
                message: "Пожалуйста, выберите дату!",
              },
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Space.Compact block size="small">
            <Form.Item
              style={{ width: "50%", margin: "0px" }}
              name="sum"
              rules={[
                {
                  required: true,
                  message: "Введите сумму платежа",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} placeholder="Сумма" />
            </Form.Item>
            <Form.Item
              style={{ width: "40%", margin: "0px" }}
              name="type"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, выберите способ оплаты!",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                placeholder="Способ оплаты"
                optionFilterProp="children"
              >
                <Option value="наличные">
                  <h4 className="option-title-font">наличные</h4>
                </Option>
                <Option value="банковский счёт">
                  <h4 className="option-title-font">банковский счёт</h4>
                </Option>
                <Option value="перевод на карту">
                  <h4 className="option-title-font">перевод на карту</h4>
                </Option>
                <Option value="карта">
                  <h4 className="option-title-font">карта</h4>
                </Option>
              </Select>
            </Form.Item>
          </Space.Compact>
          <br />

          <Form.Item
            label="Касса/Счёт для списания"
            name="counter1"
            rules={[
              {
                required: true,
                message: "Выберите кассу/счёт для списания!",
              },
            ]}
          >
            <Select
              size="small"
              placeholder="Касса/счёт"
              optionFilterProp="children"
            >
              {counters &&
                counters.map((counter) => (
                  <Option key={counter._id} value={counter._id}>
                    <h4 className="option-title-font">{counter.title}</h4>
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item label="Комментарий" name="comment">
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Button type="primary" block size="medium" htmlType="submit">
              Оплатить
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
