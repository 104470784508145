import { connect } from "react-redux";
import GenerateReportScreen from "./GenerateReportScreen";
import { createReport } from "src/storehouse/redux/actions";

export default connect(
  (state) => {
    return {
      profile: state.auth.profile,
      report: state.storehouse.report,
    };
  },
  {
    createReport,
  }
)(GenerateReportScreen);
