import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { AppContainer, hot, setConfig } from "react-hot-loader";

import "./index.css";
import App from "./App";
import store from "./store";

require("react-hot-loader/patch");

setConfig({
  showReactDomPatchNotification: false,
});

const renderApp = (Main = App) => {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <Main />
      </Provider>
    </AppContainer>,
    document.getElementById("root")
  );
};

renderApp();

if (module.hot) {
  module.hot.accept("./App", () => renderApp(require("./App").default));
}
