import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Tabs, Table, message, Button, Space, Modal } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import BackRouteButton from "src/common/components/BackRouteButton";

export default ({ removeCategory, storehouse, profile }) => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleEditCategory = async (category) => {
    history.push({
      pathname: `/storehouse/settings/category/edit`,
      state: { category },
    });
  };

  const handleDeleteCategory = async (values) => {
    setLoading(true);
    values.creatorId = profile._id;
    var result = await removeCategory(values);

    if (result.success) {
      message.success("Категория успешно удалена!");
      setLoading(false);
    } else {
      message.error(result.message || "Не удалось удалить!");
      setLoading(false);
    }
  };

  const showConfirm = (category) => {
    Modal.confirm({
      title: "Вы уверены, что хотите удалить эту категорию?",
      icon: <ExclamationCircleOutlined />,
      content: "Это действие не может быть отменено.",
      okText: "Да, удалить",
      okType: "danger",
      cancelText: "Отмена",
      onOk() {
        handleDeleteCategory(category);
      },
      onCancel() {
        console.log("Cancelled");
      },
    });
  };

  const renderCategoryTable = (categories) => {
    const columns = [
      {
        title: <span className="column-title-font">Название категории</span>,
        dataIndex: "title",
        key: "title",
        render: (text) => <span className="column-content-font">{text}</span>,
      },
      {
        title: "Действия",
        key: "action",
        render: (text, record) => (
          <Space>
            <Button
              className="common-table-button"
              type="link"
              size="small"
              icon={<EditOutlined />}
              onClick={() => handleEditCategory(record)}
            />
            <Button
              className="common-table-button"
              type="link"
              size="small"
              icon={<DeleteOutlined />}
              onClick={() => showConfirm(record)}
            />
          </Space>
        ),
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={categories}
        rowKey="_id"
        loading={loading}
        pagination={false}
      />
    );
  };

  const items = [
    {
      key: "1",
      label: "Продукты",
      children: renderCategoryTable(storehouse.productsCategories),
    },
    {
      key: "2",
      label: "Сырье",
      children: renderCategoryTable(storehouse.rawMaterialsCategories),
    },
  ];

  useEffect(() => {
    if (!storehouse) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [storehouse]);

  return (
    <div className="common-container">
      <BackRouteButton />
      <h4 className="common-title--large">Все категории склада</h4>
      <Tabs defaultActiveKey="1" items={items}></Tabs>
    </div>
  );
};
