import { connect } from "react-redux";
import CreateCounteragentScreen from "./CreateCounteragentScreen";
import { createCounteragent } from "src/counteragents/redux/actions";

export default connect(
  (state) => {
    return {
      profile: state.auth.profile,
    };
  },
  { createCounteragent }
)(CreateCounteragentScreen);
