import createReducer from "src/utils/createReducer";
import { SET_STOREHOUSE } from "./types";
import { SET_RECEIPT_ORDERS } from "./types";
import { SET_DINMEL_PRICES } from "./types";
import { SET_REPORT } from "./types";

const INITIAL_STATE = {
  storehouse: null,
  receiptOrders: null,
  pricesDinMel: null,
  report: null,
};
export default createReducer(INITIAL_STATE, {
  [SET_STOREHOUSE]: (state, action) => ({
    ...state,
    storehouse: action.storehouse,
  }),
  [SET_RECEIPT_ORDERS]: (state, action) => ({
    ...state,
    receiptOrders: action.receiptOrders,
  }),
  [SET_DINMEL_PRICES]: (state, action) => ({
    ...state,
    pricesDinMel: action.prices,
  }),
  [SET_REPORT]: (state, action) => ({
    ...state,
    report: action.report,
  }),
});
