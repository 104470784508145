import React from "react";
import { Skeleton } from "antd";

import "./index.css";

export default () => {
  return (
    <div className="sceleton-container">
      <Skeleton
        title
        active
        paragraph={{
          rows: 4,
        }}
      />
    </div>
  );
};
