import { connect } from "react-redux";
import EditStorehouseInstanceScreen from "./EditStorehouseInstanceScreen";
import { editStorehouseInstance } from "src/storehouse/redux/actions";

export default connect(
  (state) => {
    return {
      profile: state.auth.profile,
    };
  },
  {
    editStorehouseInstance,
  }
)(EditStorehouseInstanceScreen);
