import { connect } from "react-redux";
import CreateStorehouseCategoryScreen from "./CreateStorehouseInstanceScreen";
import { createStorehouseInstance } from "src/storehouse/redux/actions";

export default connect(
  (state) => {
    return {
      storehouse: state.storehouse.storehouse,
      profile: state.auth.profile,
    };
  },
  { createStorehouseInstance }
)(CreateStorehouseCategoryScreen);
