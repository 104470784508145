import { connect } from "react-redux";
import ShowAllCategoriesScreen from "./ShowAllCategoriesScreen";
import { removeCategory } from "src/storehouse/redux/actions";

export default connect(
  (state) => {
    return {
      storehouse: state.storehouse.storehouse,
      profile: state.auth.profile,
    };
  },
  {
    removeCategory,
  }
)(ShowAllCategoriesScreen);
