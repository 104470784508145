import React, { useState, useEffect } from "react";
import { Form, Input, Radio, Button, message, Spin } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import BackRouteButton from "src/common/components/BackRouteButton";

export default ({ editStorehouseInstance, profile }) => {
  const location = useLocation();
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const instance = location.state.instance;

  const onFinish = async (values) => {
    setLoading(true);
    values._id = instance._id;
    values.creatorId = profile._id;
    var result = await editStorehouseInstance(values);

    if (result.success) {
      message.success("Вид продукции/сырья успешно обновлён!");
      history.goBack();
    } else {
      message.error(
        result.message || "Ошибка при обновлении вида продукции/сырья!"
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    if (instance) {
      form.setFieldsValue({
        title: instance.title,
        type: instance.type,
      });
      setLoading(false);
    }
  }, [instance, form]);

  return (
    <div className="common-container">
      <Spin spinning={loading}>
        <BackRouteButton />
        <h4 className="common-title--large">
          Редактировать вид продукции/сырья
        </h4>
        <Form onFinish={onFinish} requiredMark={false} form={form}>
          <Form.Item
            label="Название вида продукции/сырья"
            name="title"
            rules={[
              {
                required: true,
                message: "Пожалуйста введите название вида продукции/сырья!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            requiredMark="hidden"
            label="Тип вида продукции/сырья"
            name="type"
            rules={[
              {
                required: true,
                message: "Пожалуйста выберите тип вида продукции/сырья!",
              },
            ]}
          >
            <Radio.Group>
              <Radio value="product">Продукт</Radio>
              <Radio value="rawMaterial">Сырьё</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Обновить вид продукции/сырья
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
