import axios from "axios";
import { getInitialCounters } from "src/counters/redux/actions";
import { getCounteragents } from "src/counteragents/redux/actions";
import { getStorehouse } from "src/storehouse/redux/actions";
import { getInitialOrders } from "src/orders/redux/actions";
import { getShifts } from "src/processing/redux/actions";
import { getReceiptOrders } from "src/storehouse/redux/actions";
import { SET_TOKEN, SET_PROFILE, SET_ACTIONS } from "./types";
const apiURL = process.env.API_URL;

export function setToken(token) {
  return {
    type: SET_TOKEN,
    token: token,
  };
}

export function setProfile(profile) {
  return {
    type: SET_PROFILE,
    profile: profile,
  };
}

export function setActions(actions) {
  return {
    type: SET_ACTIONS,
    actions: actions,
  };
}

export function getLast1000Actions() {
  return async (dispatch, getState) => {
    try {
      const state = await getState();
      const response = await axios.get(
        `${apiURL}/auth/last-1000-actions?loadAll=true`,
        {
          headers: {
            Authorization: state.auth.token,
          },
        }
      );
      dispatch(setActions(response.data.actions));
    } catch (error) {
      console.log(error.message);
    }
  };
}
export function getInitialActions() {
  return async (dispatch, getState) => {
    try {
      const state = await getState();
      const response = await axios.get(`${apiURL}/auth/last-1000-actions`, {
        headers: {
          Authorization: state.auth.token,
        },
      });
      dispatch(setActions(response.data.actions));
    } catch (error) {
      console.log(error.message);
    }
  };
}

export function login(values) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${apiURL}/auth`, values);
      if (response.data) {
        dispatch(setToken(response.data.token));
        dispatch(setProfile(response.data.profile));
        dispatch(getInitialActions());
        dispatch(getInitialCounters());
        dispatch(getCounteragents());
        dispatch(getStorehouse());
        dispatch(getInitialOrders());
        dispatch(getShifts());
        dispatch(getReceiptOrders());
        return { success: true };
      } else {
        return { success: false };
      }
    } catch (error) {
      console.log(error.message);
    }
  };
}
